// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_paginationContainer__n7pw2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
  }
  
  .Pagination_pageButton__uG5Dc,
  .Pagination_tabButton__FhVSb {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .Pagination_pageButton__uG5Dc:hover,
  .Pagination_tabButton__FhVSb:hover {
    background-color: #f0f0f0;
  }
  
  .Pagination_pageButton__uG5Dc:disabled,
  .Pagination_tabButton__FhVSb:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  
  .Pagination_activePage__djRq- {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }

  .Pagination_activePage__djRq-:hover {
    color: #007bff;
  }
  
  @media screen and (max-width: 768px) {
    .Pagination_paginationContainer__n7pw2 {
      gap: 6px;
      padding: 8px;
    }
  
    .Pagination_pageButton__uG5Dc,
    .Pagination_tabButton__FhVSb {
      padding: 6px 10px;
      font-size: 14px;
    }
  }
  
  /* @media screen and (max-width: 480px) {
    .paginationContainer {
      flex-direction: column;
      gap: 4px;
    }
  
    .pageButton,
    .tabButton {
      width: 100%;
      text-align: center;
      padding: 8px 0;
      font-size: 14px;
    }
  } */
  `, "",{"version":3,"sources":["webpack://./src/Components/UI/Pagination/Pagination.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,aAAa;IACb,gBAAgB;IAChB,WAAW;EACb;;EAEA;;IAEE,iBAAiB;IACjB,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;;IAEE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,qBAAqB;EACvB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE;MACE,QAAQ;MACR,YAAY;IACd;;IAEA;;MAEE,iBAAiB;MACjB,eAAe;IACjB;EACF;;EAEA;;;;;;;;;;;;;KAaG","sourcesContent":[".paginationContainer {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n    padding: 10px;\n    margin-top: 20px;\n    width: 100%;\n  }\n  \n  .pageButton,\n  .tabButton {\n    padding: 8px 12px;\n    font-size: 16px;\n    border: 1px solid #ddd;\n    background-color: #fff;\n    cursor: pointer;\n    border-radius: 4px;\n    transition: all 0.3s ease;\n  }\n  \n  .pageButton:hover,\n  .tabButton:hover {\n    background-color: #f0f0f0;\n  }\n  \n  .pageButton:disabled,\n  .tabButton:disabled {\n    background-color: #e0e0e0;\n    cursor: not-allowed;\n  }\n  \n  .activePage {\n    background-color: #007bff;\n    color: #fff;\n    border-color: #007bff;\n  }\n\n  .activePage:hover {\n    color: #007bff;\n  }\n  \n  @media screen and (max-width: 768px) {\n    .paginationContainer {\n      gap: 6px;\n      padding: 8px;\n    }\n  \n    .pageButton,\n    .tabButton {\n      padding: 6px 10px;\n      font-size: 14px;\n    }\n  }\n  \n  /* @media screen and (max-width: 480px) {\n    .paginationContainer {\n      flex-direction: column;\n      gap: 4px;\n    }\n  \n    .pageButton,\n    .tabButton {\n      width: 100%;\n      text-align: center;\n      padding: 8px 0;\n      font-size: 14px;\n    }\n  } */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `Pagination_paginationContainer__n7pw2`,
	"pageButton": `Pagination_pageButton__uG5Dc`,
	"tabButton": `Pagination_tabButton__FhVSb`,
	"activePage": `Pagination_activePage__djRq-`
};
export default ___CSS_LOADER_EXPORT___;
