import React, { useEffect } from "react";
import styles from "./Members.module.css";
import { FaTrash } from "react-icons/fa"; // Using react-icons for the trash icon
import { useDispatch, useSelector } from "react-redux";
import { getBatchMembers } from "../../../Store/API/API";
import { use } from "react";

const Members = () => {
  const members = [
    { id: 1, name: "Devdutt SINGHAL", mobile: "1873231823", isBlack: true },
    { id: 2, name: "Mohit SINGHAL", mobile: "1873231822", isBlack: true },
    { id: 3, name: "Upwan Verma", mobile: "9810001222" },
    { id: 4, name: "Divyank Chaudhary", mobile: "1231231239" },
    { id: 5, name: "Mirdul Thakur", mobile: "9971721153" },
    { id: 6, name: "Akshat Chaturvedi", mobile: "9643405624" },
    { id: 7, name: "Vedank Verma", mobile: "1231231230" },
    { id: 8, name: "Devansh Patel", mobile: "1231231231" },
    { id: 9, name: "Munesh Patil", mobile: "1231231236" },
    { id: 8, name: "Devansh Patel", mobile: "1231231231" },
    { id: 9, name: "Munesh Patil", mobile: "1231231236" },
    { id: 8, name: "Devansh Patel", mobile: "1231231231" },
    { id: 9, name: "Munesh Patil", mobile: "1231231236" },
    { id: 8, name: "Devansh Patel", mobile: "1231231231" },
    { id: 9, name: "Munesh Patil", mobile: "1231231236" },
    { id: 8, name: "Devansh Patel", mobile: "1231231231" },
    { id: 9, name: "Munesh Patil", mobile: "1231231236" },
  ];

  const [batchMembers, setBatchMembers] = React.useState([]);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const fetchmembers = async () => {
    const data = await getBatchMembers(
      batchState.groupId,
      userState.token,
      dispatch
    );

    if (data.statuscode === "200") {
      setBatchMembers(data.data);
    }
  };

  useEffect(() => {
    fetchmembers();

    // eslint-disable-next-line
  }, [batchState.batchId]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.memberCount}>
          <span className={styles.count}>{batchMembers.length}</span>
          <p>Number of members</p>
        </div>
      </div>

      <div className={styles.memberGrid}>
        {batchMembers.map((member) => (
          <div key={member?.user_id} className={styles.memberCard}>
            <div className={styles.memberIcon}>
              <img src={member?.profile_image} alt="black-icon" />
            </div>
            <div className={styles.memberDetails}>
              <p className={styles.memberName}>{member?.name}</p>
              <div className={styles.studentInfo}>
                <p className={styles.memberMobile}>
                  Mobile: {member?.mobile_number}
                </p>
                <p className={styles.memberMobile}>
                  Role: {member?.user_type}
                </p>
                <p
                  className={styles.memberMobile}
                  style={{ fontWeight: "bold" }}
                >
                  User Id: {member?.user_id}
                </p>
              </div>
            </div>
            {/* <div className={styles.trashIcon}>
              <FaTrash />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Members;
