// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calender_container__MVtnQ {
  font-family: sans-serif;
  text-align: center;
  padding: 15px;
  background: white;
  max-width: 80vw; /* Ensures responsiveness */
  overflow-x: auto; /* Allows scrolling if content exceeds width */
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 700px) {
  .Calender_container__MVtnQ {
    max-width: 75vw;    
  }
}

@media (max-width: 500px) {
  .Calender_container__MVtnQ {
    max-width: 75vw;    
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/Calender/Calender.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,eAAe,EAAE,2BAA2B;EAC5C,gBAAgB,EAAE,8CAA8C;EAChE,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".container {\n  font-family: sans-serif;\n  text-align: center;\n  padding: 15px;\n  background: white;\n  max-width: 80vw; /* Ensures responsiveness */\n  overflow-x: auto; /* Allows scrolling if content exceeds width */\n  border-radius: 8px;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n@media (max-width: 700px) {\n  .container {\n    max-width: 75vw;    \n  }\n}\n\n@media (max-width: 500px) {\n  .container {\n    max-width: 75vw;    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Calender_container__MVtnQ`
};
export default ___CSS_LOADER_EXPORT___;
