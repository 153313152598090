import React, { useEffect, useState } from "react";
import styles from "./QuestionBank.module.css";
import { getAttendanceMonth } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";
import MCQ from "../../UI/OuestionTypes/MCQ/MCQ";
import FIB from "../../UI/OuestionTypes/FIB/FIB";
import TF from "../../UI/OuestionTypes/TF/TF";
import MCQMA from "../../UI/OuestionTypes/MCQMA/MCQMA";
import Integer from "../../UI/OuestionTypes/Integer/Integer";
import Pagination from "../../UI/Pagination/Pagination";

const width = window.innerWidth;

const SelectInputField = ({
  placeholder,
  field,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <SelectInput
      width={width < 600 ? "100%" : "200px"}
      placeHolder={placeholder}
      options={dropDownoptions}
      field={field}
      handleInputChange={handleInputChange}
      value={dropDownValues}
    />
  );
};

const SelectInputField2 = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {heading} <span className={styles.required}>*</span>
      </label>
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={width <= 768 ? "200px" : "100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          backgroundColor={"#1b3156"}
          border={"none"}
          dropdownTextColor={"#FFFFFF"}
        />
      </div>
    </div>
  );
};

const TypeCard = ({ number, heading, selected }) => {
  return (
    <div
      className={styles.typeCardContainer}
      style={{ backgroundColor: selected ? "#ffc107" : "" }}
    >
      <div className={styles.numberCard}>{number}</div>
      <p className={styles.typeCardHeading}>{heading}</p>
    </div>
  );
};

const ViewQuestionBank = () => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [progressFlag, setProgressFlag] = React.useState("");
  const totalPages = 20;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [selectedMonth, setSelectedMonth] = useState({
    month: { label: "Dec 2024", value: "12 2024" },
  });
  const [dropDownOptions, setDropDownOptions] = useState({
    month: [
      { label: "November 2024", value: "November 2024" },
      { label: "December 2024", value: "December 2024" },
    ],
  });
  const handleInputChange = async (field, value) => {
    setSelectedMonth((prev) => ({
      ...prev,
      [field]: value, // Update the correct field (e.g., 'month')
    }));
  };

  const fetchMonths = async () => {
    const data = await getAttendanceMonth(
      batchState.groupId,
      userState.token,
      dispatch
    );

    const monthtonumber = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };

    const monthYear = data?.map((item) => {
      const [month, year] = item.value.split(",");
      const monthNumber = monthtonumber[month.trim()];
      return {
        label: `${month.trim()} ${year.trim()}`,
        value: `${monthNumber} ${year.trim()}`,
      };
    });

    setDropDownOptions({ month: monthYear });
    // console.log(data, monthYear);
  };

  const mcqQuestion = {
    question: "What is the capital of India?",
    options: [
      {
        name: "Answer Is A AND B",
        answer_value: "1",
        answer_key: "A",
      },
      {
        name: "Answer Is B AND B",
        answer_value: "2",
        answer_key: "B",
      },
      {
        name: "Answer Is C AND B",
        answer_value: "3",
        answer_key: "C",
      },
      {
        name: "Answer Is  D AND B",
        answer_value: "4",
        answer_key: "D",
      },
    ],
    questionOrder: 1,
    answer: "A",
  };
  const fibQuestion = {
    question: "What is the capital of India?",
    options: ["red", "blue"],
    questionOrder: 1,
    answer: "A",
  };
  const trueFalseQuestion = {
    question: "What is the capital of India?",
    questionOrder: 1,
    answer: "A",
  };
  const mcqmaQuestion = {
    question: "What is the capital of India?",
    options: [
      {
        name: "Answer Is A AND B",
        answer_value: "1",
        answer_key: "A",
      },
      {
        name: "Answer Is B AND B",
        answer_value: "2",
        answer_key: "B",
      },
      {
        name: "Answer Is C AND B",
        answer_value: "3",
        answer_key: "C",
      },
      {
        name: "Answer Is  D AND B",
        answer_value: "4",
        answer_key: "D",
      },
    ],
    questionOrder: 1,
    answer: "A,C,D",
  };
  const integerQuestion = {
    question: "What is the capital of India?",
    questionOrder: 1,
    answer: "#2#,2",
  };

  //   useEffect(() => {
  //     fetchMonths();
  //     // eslint-disable-next-line
  //   }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <p className={styles.heading}>Assignment</p>
        <SelectInputField
          dropDownValues={selectedMonth}
          dropDownoptions={dropDownOptions}
          handleInputChange={handleInputChange}
          field={"month"}
        />
      </div>
      <div className={styles.questionTypeContainer}>
        <SelectInputField2 heading={"Class"} />
        <SelectInputField2 heading={"Subject"} />
        <SelectInputField2 heading={"Chapter"} />
        <SelectInputField2 heading={"Is Verified"} />
        <SelectInputField2 heading={"Topic"} />
        <div style={{ alignSelf: "flex-end" }}>
          <p
            style={{
              color:
                progressFlag === "Please select all fields" ? "red" : "green",
            }}
          >
            {progressFlag}
          </p>
          <div className={styles.markButton} onClick={""}>
            <p>View</p>
          </div>
        </div>
      </div>
      <div className={styles.questionsContainer}>
        <MCQ
          answer={mcqQuestion.answer}
          correctOption={mcqQuestion.options}
          question={mcqQuestion.question}
          questionOrder={mcqQuestion.questionOrder}
          location={"QB"}
          questionId={1}
          questionMarks={1}
        />
        <FIB
          correctOption={fibQuestion.options}
          question={fibQuestion.question}
          questionOrder={1}
          fibCount={2}
          location={"QB"}
        />
        <TF
          answer={trueFalseQuestion.answer}
          question={trueFalseQuestion.question}
          questionOrder={trueFalseQuestion.questionOrder}
          location={"QB"}
        />
        <MCQMA
          answer={mcqmaQuestion.answer}
          question={mcqmaQuestion.question}
          questionOrder={mcqmaQuestion.questionOrder}
          correctOption={mcqmaQuestion.options}
          location={"QB"}
        />
        <Integer
          correctOption={integerQuestion.answer}
          question={integerQuestion.question}
          questionOrder={integerQuestion.questionOrder}
          location={"QB"}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ViewQuestionBank;

// import { useEffect } from "react";
// import Editor from "../../Editor/Editor";
// const QuestionBank = () => {
//   return (
//     <div>
//       <Editor
//         key={1}
//         height={"8rem"}
//         width={"80%"}
//         placeholder="write your content ...."
//       />
//       <Editor
//         key={2}
//         height={"8rem"}
//         width={"80%"}
//         placeholder="write your content ...."
//       />
//       <Editor
//         key={3}
//         height={"8rem"}
//         width={"80%"}
//         placeholder="write your content ...."
//       />
//     </div>
//   );
// };

// export default QuestionBank;
