import { createSlice } from "@reduxjs/toolkit";
const applicationSlice = createSlice({
  name: "application",
  initialState: {
    batchData: {
      groupId: "",
      batchName: "",
      batchColor: "",
      batchUserType: "",
    },
    alertData: {
      isAlert: false,
      alertMessage: "",
      // alertType: "danger" || "success";
      alertType: "",
    },
    schoolData: {
      schoolName: "",
      schoolCode: "",
      schoolImage: "",
      favicon: "",
    },
  },
  reducers: {
    setBatchData: (state, action) => {
      state.batchData = action.payload;
    },
    setAlertData: (state, action) => {
      state.alertData = action.payload;
    },
    setSchoolData: (state, action) => {
      state.schoolData = action.payload;
    },
  },
});

export const applicationActions = applicationSlice.actions;
export default applicationSlice;
