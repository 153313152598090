import React from "react";
import styles from "./Integer.module.css";
import Tag from "../Tag/Tag";
import EditIcon from "@mui/icons-material/Edit";
import QuestionsBottomTag from "../QuestionsBottomTag/QuestionsBottomTag";
import QuestionTopTag from "../QuestionsTopTag/QuestionTopTag";

const Integer = ({
  correctOption,
  question,
  questionOrder,
  location,
  questionId,
  questionMarks,
}) => {
  return (
    <div className={styles.mcqContainer}>
      <QuestionTopTag
        questionId={questionId}
        questionMarks={questionMarks}
        location={location}
      />
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            {location !== "QB" ? (
              <input
                type="text"
                className={styles.questionInputBox}
                value={questionOrder}
              />
            ) : (
              <div className={styles.questionInputBox}>{questionOrder}</div>
            )}
            <div
              style={{ display: "ruby" }}
              dangerouslySetInnerHTML={{
                __html: question || " ",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        <div
          className={styles.mcqOptionContainer}
          style={{ marginLeft: "30px" }}
        >
          <div
            className={
              correctOption === "True"
                ? styles.mcqOptionCorrect
                : styles.mcqOption
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `Answer: ${correctOption?.split(",")[1] || ""}`,
              }}
            ></div>
          </div>
        </div>
      </div>
      {location === "QB" && (
        <div className={styles.QuestionBankContainer}>
          <QuestionsBottomTag />
        </div>
      )}
    </div>
  );
};

export default Integer;
