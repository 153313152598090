import React, { useEffect, useState } from "react";
import styles from "./CommonSession.module.css";
import CommonSessionCard from "./CommonSessionCard";
import CreateModal from "./CreateModal";
import Filter from "../../UI/Filter/Filter";
import CustomModal from "../../UI/CustomModal/CustomModal";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getGroupSession } from "../../../Store/API/API";
import Form from "react-bootstrap/Form";

const Upcomming2 = () => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [historySessions, setHistorySessions] = useState([]);
  const [cancelledSessions, setCancelledSessions] = useState([]);
  const [displaySessionsData, setDisplaySessionsData] = useState([]);
  const [activeSession, setActiveSession] = useState("Upcoming");

  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const SessionButton = ({ name, isActive, parentFunction }) => {
    return (
      <div
        className={
          isActive ? styles.upcommingButtonActive : styles.upcommingButton
        }
        onClick={parentFunction}
      >
        <p
          className={
            isActive ? styles.sessionbuttonTextActive : styles.sessionbuttonText
          }
        >
          {name}
        </p>
      </div>
    );
  };

  const filterOptions = [
    {
      type: "select",
      placeholder: "Select Subject",
      options: [
        { value: "Physics", label: "Physics" },
        { value: "Chemistry", label: "Chemistry" },
        { value: "Maths", label: "Maths" },
        { value: "Biology", label: "Biology" },
      ],
      onChange: setSelectedSubject,
      selectedValue: selectedSubject,
    },
    {
      type: "select",
      placeholder: "Select Class",
      options: [
        { value: "Class 1", label: "Class 1" },
        { value: "Class 2", label: "Class 2" },
        { value: "Class 3", label: "Class 3" },
        { value: "Class 4", label: "Class 4" },
      ],
      onChange: setSelectedClass,
      selectedValue: selectedClass,
    },
  ];

  const handleApplyFilter = () => {
    // console.log("Selected Subject: ", selectedSubject);
    // console.log("Selected Class: ", selectedClass);
  };

  const fetchSessions = async ({ sessionType }) => {
    // console.log("Session Type: ", sessionType);

    if (sessionType === "upcomingButton") {
      setActiveSession("Upcoming");
      setDisplaySessionsData(upcomingSessions);
      return;
    } else if (sessionType === "past") {
      setActiveSession("History");
      if (historySessions.length > 0) {
        setDisplaySessionsData(historySessions);
        return;
      }
    } else if (sessionType === "cancel") {
      setActiveSession("Cancelled");
      if (cancelledSessions.length > 0) {
        setDisplaySessionsData(cancelledSessions);
        return;
      }
    }

    setIsLoading(true);

    const data = await getGroupSession(
      userState,
      batchState?.groupId,
      sessionType,
      true,
      dispatch
    );
    if (data) {
      // console.log(data);
      if (sessionType === "upcoming") {
        setUpcomingSessions(data);
        setDisplaySessionsData(data);
      }
      if (sessionType === "past") {
        setHistorySessions(data);
        setDisplaySessionsData(data);
      }
      if (sessionType === "cancel") {
        setCancelledSessions(data);
        setDisplaySessionsData(data);
      }
      setIsLoading(false);
    }
  };

  const handleUpdateSession = async (
    type,
    sessionId,
    url,
    uploadType,
    data
  ) => {
    if (type === "cancelled") {
      // console.log("Cancelled Session ID: ", sessionId);

      const tempData = displaySessionsData.filter(
        (session) => session.sessionId !== sessionId
      );
      const tempData2 = upcomingSessions.filter(
        (session) => session.sessionId !== sessionId
      );
      setDisplaySessionsData(tempData);
      setUpcomingSessions(tempData2);
      setCancelledSessions([]);
      return;
    }

    if (type === "upcoming") {
      fetchSessions({ sessionType: "upcoming" });
      return;
    }

    if (type === "history") {
      console.log("History Session ID: ", sessionId, url, uploadType);

      const updatedHistory = historySessions.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            ...(uploadType === "notes"
              ? { notes: url }
              : { recordingUrl: url, recordingUrlmp4: url }),
          };
        }
        return session;
      });

      const updatedDisplayData = displaySessionsData.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            ...(uploadType === "notes"
              ? { notes: url }
              : { recordingUrl: url, recordingUrlmp4: url }),
          };
        }
        return session;
      });

      console.log("updatedDisplayData: ", updatedDisplayData);

      setHistorySessions(updatedHistory);
      setDisplaySessionsData(updatedDisplayData);
      return;
    }

    if (type === "topicChanged") {
      console.log("Topic Changed", data, sessionId);

      const updateHistory = historySessions.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            session_topic: data,
          };
        }
        return session;
      });

      const updateDisplayData = displaySessionsData.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            session_topic: data,
          };
        }
        return session;
      });

      setHistorySessions(updateHistory);
      setDisplaySessionsData(updateDisplayData);
      return;
    }

    // fetchSessions({ sessionType: activeSession });
  };

  useEffect(() => {
    // Fetching Upcomming Session
    fetchSessions({ sessionType: "upcoming" });

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      {creatingSession && <Spinner />}
      <div className={styles.headingContainer}>
        <div className={styles.headingButtonContainer}>
          <p className={styles.heading}>Live Sessions</p>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <SessionButton
              name="Upcoming"
              isActive={activeSession === "Upcoming"}
              parentFunction={() =>
                fetchSessions({ sessionType: "upcomingButton" })
              }
            />
            <SessionButton
              name="History"
              isActive={activeSession === "History"}
              parentFunction={() => fetchSessions({ sessionType: "past" })}
            />
            <SessionButton
              name="Cancelled"
              isActive={activeSession === "Cancelled"}
              parentFunction={() => fetchSessions({ sessionType: "cancel" })}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          {batchState?.batchUserType !== "student" && (
            <div
              className={styles.createSession}
              onClick={() => setOpenCreateModal(true)}
            >
              <p className={styles.createSessionText}>Create Session</p>
            </div>
          )}
          {/* <div className={styles.webFilter}>
            <Filter
              options={filterOptions}
              parentFunction={handleApplyFilter}
              filterPosition={"left"}
            />
          </div> */}
          <div className={styles.mobileFilter}>
            <Filter
              options={filterOptions}
              parentFunction={handleApplyFilter}
              filterPosition={"center"}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {displaySessionsData.length > 0 ? (
          <div className={styles.gridContainer}>
            {displaySessionsData?.map((session, index) => {
              return (
                <CommonSessionCard
                  key={index}
                  session={session}
                  sessionType={activeSession}
                  handleUpdateSession={handleUpdateSession}
                  setCreatingSession={setCreatingSession}
                />
              );
            })}
          </div>
        ) : (
          <div className={styles.noSessionContainer}>
            <p className={styles.noSessionText}>No Sessions Found</p>
          </div>
        )}
      </div>

      <CustomModal
        isOpen={openCreateModal}
        onClose={() => setOpenCreateModal(!openCreateModal)}
      >
        <CreateModal
          onClose={() => setOpenCreateModal(!openCreateModal)}
          setCreatingSession={setCreatingSession}
          handleUpdateSession={handleUpdateSession}
          // creatingSession={setCreatingSession}
        />
      </CustomModal>
    </div>
  );
};

export default Upcomming2;
