import React, { useState, useEffect } from "react";
import classes from "./Dashboard.module.css";
import styles from "./../Session/CommonSessionCard.module.css"
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardCourse,
  dashboardSession,
  dashboardTest,
  dashboardVedio,
  getSessionJoinningUrl,
  getVedioViewTime,
} from "../../../Store/API/API";
import VideoPlayer from "./Videoplayer/VideoPlayer";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import { styled, Tooltip, tooltipClasses, Typography } from "@mui/material";

const Dashboard = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [dashboardCourses, setDashboardCourses] = useState([]);
  const [dashboardVedios, setDashboardVedios] = useState([]);
  const [dashboardTests, setDashboardTests] = useState([]);
  const [dashboardSessions, setDashboardSessions] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [selectedMP4, setSelectedMP4] = useState();
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [linkButtonText, setLinkButtonText] = useState("Copy Link");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchTests = async () => {
    const data = await dashboardTest(userState.userId, dispatch);
    // console.log(data, "data");
    if (data) {
      setDashboardTests(data);
    }
    setIsLoading(false);
  };
  const fetchCourses = async () => {
    const data = await dashboardCourse(
      userState.userId,
      "shop",
      userState.token,
      dispatch
    );
    if (data) {
      setDashboardCourses(data.shop_data);
    }
    setIsLoading(false);
  };
  const fetchVedios = async () => {
    const data = await dashboardVedio(
      userState.userId,
      userState.token,
      "video",
      dispatch
    );
    if (data) {
      setDashboardVedios(data.videos);
    }
    setIsLoading(false);
  };
  const fetchSession = async () => {
    const data = await dashboardSession(
      userState.userId,
      userState.token,
      // "session",
      dispatch
    );
    if (data) {
      // console.log(data.session);
      setDashboardSessions(data.session);
    }
    setIsLoading(false);
  };
  const handleJoinSession = async (event, session, batchId, triggerSource) => {
    event.preventDefault();
    // console.log(session, batchId);
    // const data = await getSessionJoinningUrl(
    //   userState.token,
    //   userState.userId,
    //   session,
    //   batchId,
    //   dispatch
    // );

    // if (data) {
    //   // console.log(data);
    //   window.open(data.meetingUrl, "_blank");
    // }

    try {
      // Ensure that the function is called in response to a user action

      let newTab = "";

      if (triggerSource === "copyButton") {
        setLinkButtonText("Copying...");
      }

      if (triggerSource !== "copyButton") {
        newTab = window.open("about:blank", "_blank");
        if (!newTab) {
          throw new Error("Link opened on new tab. Please check your browser.");
        }
      }

      const data = await getSessionJoinningUrl(
        userState.token,
        userState.userId,
        session,
        batchId,
        dispatch
      );

      // console.log("API Response:", data);

      if (!data || !data.meetingUrl) {
        throw new Error("Invalid response or missing meeting URL");
      }

      if (triggerSource === "copyButton") {
        try {
          await navigator.clipboard.writeText(data.meetingUrl);
          setLinkButtonText("Link Copied");

          dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Meeting URL copied to clipboard",
              alertType: "success",
            })
          );

          setTimeout(() => {
            dispatch(
              applicationActions.setAlertData({
                isAlert: false,
                alertMessage: "",
                alertType: "",
              })
            );
            setLinkButtonText("Copy Link");
          }, 3000);
        } catch (error) {
          // Handle clipboard copy failure
          console.error("Failed to copy the meeting URL: ", error);

          dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Failed to copy the meeting URL. Please try again.",
              alertType: "error",
            })
          );

          setTimeout(() => {
            dispatch(
              applicationActions.setAlertData({
                isAlert: false,
                alertMessage: "",
                alertType: "",
              })
            );
            // setLinkButtonText("Copy Link");
          }, 3000);
        }

        setLinkButtonText("Copy Link");
        return;
      }

      // Open the meeting URL in the new tab
      newTab.location.href = data.meetingUrl;

      // Optionally, you can close the new tab if the URL is not valid
      // newTab.close(); // Uncomment if you want to close the tab on error
    } catch (error) {
      console.error("Error joining session:", error);
      window.alert(`${error.message || error}`);
    }
  };
  const openVideoModal = async (videoUrl, data) => {
    setSelectedVideoUrl(videoUrl);
    // console.log("my data=", data);
    setSelectedMP4(data);
    const tempData = {
      student_id: userState.userId,
      content_type: "shop",
      content_id: data.content_id,
    };
    const apiData = await getVedioViewTime(tempData, userState.token, dispatch);
    if (apiData) {
      // console.log(" before current time ",currentViewTime);
      setCurrentViewTime(apiData.data.current_view_time);
      setShowVideoModal(true);
    }
    // console.log(apiData);
    // setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setSelectedVideoUrl("");
    setSelectedMP4();
    setCurrentViewTime(0);
    setShowVideoModal(false);
  };

  const renderTooltipContent = () => (
    <Typography
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "300px", // Adjust width as needed
      }}
    >
      <div className={styles.infoInstructions}>
        <h3 className={styles.infoH3}>
          Instructions If you are using an iPad or mobile:
        </h3>
        <ul className={styles.infoUl}>
          <li className={styles.infoLi}>
            First download the Big Blue Button app from the iOS/Play Store.
          </li>
          <li className={styles.infoLi}>
            Copy the link from the session card.
          </li>
          <li className={styles.infoLi}>Paste the link into the app.</li>
        </ul>
        <p className={styles.infoNote}>
          Note: If you click on the "Start" button on an iPad, you will not be
          able to share your screen.
        </p>

        <h3 className={styles.infoH3}>If you are using a laptop:</h3>
        <ul className={styles.infoUl}>
          <li className={styles.infoLi}>Simply click the "Start" button.</li>
        </ul>
      </div>
    </Typography>
  );

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      // boxShadow: theme.shadows[1],
      boxShadow: "2px 4px 60px 0px #0732781A",
      borderRadius: "10px",
      maxWidth: 500,
    },
  }));

  useEffect(() => {
    // API call to get courses
    fetchCourses();
    // API call to get vedios
    fetchVedios();
    // API call to get tests
    fetchTests();
    // API call to get session
    fetchSession();
    // eslint-disable-next-line
  }, [userState]);

  const SectionCard = ({ cardType, data, isVedio, isCourse }) => {
    // if (cardType === "session") {
    //   console.log("Session card data", data.date, data.fromTime);
    // }

    const [timeLeft, setTimeLeft] = useState("");
    const [testStarted, setTestStarted] = useState(false);
    const startDate = `${data?.date} | ${data?.fromTime}`;

    useEffect(() => {
      const calculateTimeLeft = () => {
        const assignDate = new Date(startDate?.replace(" | ", " "));
        const now = new Date();
        const difference = assignDate - now;

        if (difference > 0) {
          const hours = Math.floor(difference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          const days = Math.floor(hours / 24);
          setTimeLeft(
            days > 0
              ? `${days} days ${
                  hours % 24
                } hours ${minutes} minutes ${seconds} seconds left`
              : `${hours} hours ${minutes} minutes ${seconds} seconds left`
          );
        } else {
          setTimeLeft("Session has started");
          setTestStarted(true);
        }
      };

      calculateTimeLeft();
      const timer = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timer);
    }, [data?.date, data?.fromTime, startDate]);

    return (
      <div
        className={classes.sectionCard}
        onClick={() => {
          if (isCourse) {
            // console.log(data);
            navigate("/course-details", {
              state: { course_id: data.course_id },
            });
          }
        }}
        style={{ cursor: isCourse ? "pointer" : "default" }}
      >
        {cardType === "normal" ? (
          isVedio === true ? (
            <div className={classes.vedioContainer}>
              <i
                className={`fa-regular fa-circle-play fa-3x ${classes.vedioThumbnail} `}
                style={{ cursor: "pointer" }}
                onClick={() => openVideoModal(data?.url, data)}
              ></i>
              {data?.thumb_nail ? (
                <img
                  alt="thumbnail"
                  src={data?.thumb_nail}
                  style={{ cursor: "pointer" }}
                  className={classes.cardImage}
                  onClick={() => openVideoModal(data?.url, data)}
                ></img>
              ) : (
                <div
                  className={classes.cardImage3}
                  onClick={() => openVideoModal(data?.url, data)}
                ></div>
              )}
            </div>
          ) : (
            <img
              alt="thumbnail"
              src={data?.cover_photo_url || data?.thumb_nail}
              className={classes.cardImage}
            ></img>
          )
        ) : (
          <img
            alt="thumbnail"
            src={"/images/sessionLogo.png"}
            className={classes.cardImage2}
          ></img>
        )}
        {/* {cardType === "normal" ? (
          <img
            src={data?.cover_photo_url || data?.thumb_nail}
            className={classes.cardImage}
          ></img>
        ) : (
          <div className={classes.cardImage2}></div>
        )} */}
        <div className={classes.contentContainer}>
          <p className={classes.cardHeading}>{data?.name}</p>
          {!isVedio && (
            <p className={classes.cardHeading}>
              {data?.curriculum || data?.batch_name} |{" "}
              {data?.class || `${data?.fromTime} - ${data?.toTime}`} |{" "}
              {data?.subject || data?.date}
            </p>
          )}
          {isVedio && <p className={classes.cardHeading}>{data?.title}</p>}
          <p className={classes.cardSubHeading}>By: Innovartan</p>
          {cardType === "session" && (
            <div>
              <p
                className={classes.testSubheading}
                style={{ marginTop: "-8px", marginBottom: "2px" }}
              >
                Starts In:{" "}
                <span style={{ fontWeight: "bold" }}>{timeLeft}</span>
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={classes.cardButton}
                  style={{
                    backgroundColor: testStarted ? "" : "#f8a27a",
                    cursor: testStarted ? "pointer" : "not-allowed",
                  }}
                >
                  <button
                    className={classes.cardButton}
                    style={{
                      backgroundColor: testStarted ? "" : "#f8a27a",
                      cursor: testStarted ? "pointer" : "not-allowed",
                    }}
                    onClick={(e) => {
                      testStarted &&
                        handleJoinSession(e, data.sessionId, data.batch_id);
                    }}
                  >
                    {testStarted ? "Join Now" : "Upcoming"}
                  </button>
                </div>
                {testStarted && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                    }}
                  >
                    <div
                      className={classes.cardButton}
                      style={{
                        backgroundColor: testStarted ? "" : "#f8a27a",
                        cursor:
                          linkButtonText === "Copy Link"
                            ? "pointer"
                            : "not-allowed",
                        padding: "5px",
                        width: "40px",
                        borderRadius: "10px",
                      }}
                      onClick={(e) => {
                        handleJoinSession(
                          e,
                          data.sessionId,
                          data.batch_id,
                          "copyButton"
                        );
                      }}
                    >
                      {linkButtonText === "Copy Link" ? (
                        <ContentCopyIcon />
                      ) : (
                        <HourglassBottomOutlinedIcon />
                      )}
                    </div>
                    <LightTooltip
                      title={renderTooltipContent()}
                      placement="bottom"
                      arrow
                    >
                      <div
                        className={classes.cardButton}
                        style={{
                          backgroundColor: testStarted ? "" : "#f8a27a",
                          cursor: testStarted ? "pointer" : "not-allowed",
                          padding: "5px",
                          width: "40px",
                          borderRadius: "10px",
                        }}
                      >
                        <InfoOutlinedIcon />
                      </div>
                    </LightTooltip>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const TestCard = ({ subject, completed, data }) => {
    //For calculating time left for test to start
    const [timeLeft, setTimeLeft] = useState("");
    const [testStarted, setTestStarted] = useState(false);

    useEffect(() => {
      const calculateTimeLeft = () => {
        const assignDate = new Date(data?.assign_date.replace(" | ", " "));
        const now = new Date();
        const difference = assignDate - now;

        if (difference > 0) {
          const hours = Math.floor(difference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          const days = Math.floor(hours / 24);
          setTimeLeft(
            days > 0
              ? `${days} days ${
                  hours % 24
                } hours ${minutes} minutes ${seconds} seconds left`
              : `${hours} hours ${minutes} minutes ${seconds} seconds left`
          );
        } else {
          setTimeLeft("Test has started");
          setTestStarted(true);
        }
      };

      calculateTimeLeft();
      const timer = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timer);
    }, [data?.assign_date]);

    const colorMap = {
      Physics: { backgroundColor: "#BAE5F5", lineColor: "#000E8F" },
      Chemistry: { backgroundColor: "#FDE1AC", lineColor: "#FF9330" },
      Maths: { backgroundColor: "#CCEFBF", lineColor: "#44FB00" },
      Biology: { backgroundColor: "#D9D9D9", lineColor: "#717171" },
      Others: { backgroundColor: "#FFD6E7", lineColor: "#FF0080" },
    };
    const testStatus = {
      0: "Upcoming",
      1: "Take Test",
    };

    return (
      <div
        className={classes.testCard}
        style={{
          backgroundColor:
            colorMap[subject]?.backgroundColor ||
            colorMap["Others"].backgroundColor,
        }}
      >
        <p className={classes.testStatus}>
          {testStarted ? testStatus[1] : testStatus[0]}
        </p>
        <div
          className={classes.testHorizontalLine}
          style={{
            backgroundColor:
              colorMap[subject]?.lineColor || colorMap["Others"].lineColor,
          }}
        ></div>
        <img
          src={
            ["Physics", "Chemistry", "Maths", "Biology"].includes(subject)
              ? `/images/${subject}.png`
              : "/images/TEST.svg"
          }
          alt=""
          className={classes.testImage}
        />

        <p className={classes.testHeading}>
          {subject} - {data?.title}
        </p>
        <p className={classes.testSubheading}>
          Test Duration: {data?.timelimit || "6 hours"}
        </p>
        <p className={classes.testSubheading} style={{ marginTop: "-10px" }}>
          Starts In: <span style={{ fontWeight: "bold" }}>{timeLeft}</span>
        </p>
        <button
          className={
            testStarted ? classes.testButton : classes.testButtonDisabled
          }
          onClick={() =>
            testStarted &&
            navigate("/startTest", { state: { quizId: data.quiz_id } })
          }
          disabled={!testStarted}
        >
          {testStarted ? "Start Test" : "Upcoming"}
        </button>
      </div>
    );
  };

  const Section = ({ heading, cardType, test, data, isVedio, isCourse }) => {
    return (
      <div className={classes.section}>
        <div className={classes.sectionOuter}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ImportContactsIcon />
            <span style={{ marginLeft: "10px" }}>{heading}</span>
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.sectionCardContainer}>
          {!test &&
            data?.map((test, index) => (
              <SectionCard
                cardType={cardType}
                data={test}
                key={index}
                isVedio={isVedio}
                isCourse={isCourse}
              />
            ))}
        </div>
        <div className={classes.sectionCardContainer}>
          {test &&
            data?.map((test, index) => (
              <TestCard
                subject={test?.test_subjects[0]}
                completed={test?.completed}
                key={index}
                data={test}
              />
            ))}
        </div>
      </div>
    );
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.courseDashboard}>
      {dashboardCourses?.length > 0 ? (
        <Section
          heading={"My Courses"}
          cardType={"normal"}
          test={false}
          isCourse={true}
          data={dashboardCourses}
        />
      ) : null}
      {dashboardVedios?.length > 0 ? (
        <Section
          heading={"Free Videos"}
          cardType={"normal"}
          test={false}
          data={dashboardVedios}
          isVedio={true}
        />
      ) : null}
      {dashboardTests?.length > 0 ? (
        <Section
          heading={"Tests"}
          cardType={"normal"}
          test={true}
          data={dashboardTests}
        />
      ) : null}
      {dashboardSessions?.length > 0 ? (
        <Section
          heading={"Live Sessions"}
          cardType={"session"}
          test={false}
          data={dashboardSessions}
        />
      ) : null}
      {dashboardCourses?.length === 0 &&
        dashboardVedios?.length === 0 &&
        dashboardTests?.length === 0 &&
        dashboardSessions?.length === 0 && (
          <div className={classes.noData}>
            <h5>No Data Found</h5>
          </div>
        )}
      {showVideoModal && (
        <div className={classes.videoModal}>
          <div className={classes.videoModalContent}>
            <span className={classes.close} onClick={closeVideoModal}>
              &times;
            </span>
            <VideoPlayer
              videoUrl={selectedVideoUrl}
              fileData={selectedMP4}
              currentViewTimeParent={currentViewTime}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
