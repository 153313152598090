import { useDispatch, useSelector } from "react-redux";
import styles from "./CancelModal.module.css";
import { useState, useCallback } from "react";
import { cancelSession } from "../../../Store/API/API";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import SelectInput from "../../UI/SelectInput/SelectInput";

// Move this component outside to avoid unnecessary re-creation
const SelectInput2 = ({ label, required, placeholder, value, onChange }) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        <textarea
          className={styles.textArea}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

const SelectInput3 = ({
  label,
  dropdownOptions,
  icon,
  required,
  placeholder,
  formData,
  handleInputChange,
  field,
}) => {
  // console.log("field", field, formData[field]);

  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        {icon && (
          <img loading="lazy" src={icon} alt="" className={styles.icon} />
        )}
        <SelectInput
          width={"100%"}
          options={dropdownOptions}
          value={formData}
          handleInputChange={handleInputChange}
          field={field}
          placeHolder={placeholder}
          border={"none"}
        />
      </div>
    </div>
  );
};

const CancelModal = ({ onClose, handleUpdateSession, cancelModalData }) => {
  const handleClose = () => onClose(false);

  const [cancelReason, setCancelReason] = useState("");
  const [cancelling, setCancelling] = useState(false);
  const [error, setError] = useState(null);
  const [sendAlert, setSendAlert] = useState({
    setAlert: {
      label: "No",
      value: 0,
    },
  });
  const [dropDownOptions] = useState({
    setAlert: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
  });

  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  // Stable change handler
  const handleInputChange = useCallback((e) => {
    setCancelReason(e.target.value);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!cancelReason) {
      setError("Please provide a reason to cancel the session");

      setTimeout(() => {
        setError(null);
      }, 2000);
      return;
    }

    setCancelling(true);

    const tempData = {
      sessionId: cancelModalData.sessionId,
      cancelReason: cancelReason,
      userId: userState.userId,
      sendAlert: sendAlert.setAlert.value,
    };

    // console.log("tempData", tempData);

    const data = await cancelSession(tempData, userState.token, dispatch);

    if (data?.statuscode === "200") {
      handleUpdateSession("cancelled", cancelModalData.sessionId);
      setCancelling(false);
    }

    setCancelReason("");
    setCancelling(false);
    handleClose();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div
            className={styles.crossIcon}
            onClick={() => {
              handleClose();
            }}
          ></div>
          <header className={styles.header}>
            <div className={styles.headerContainer}>
              <p className={styles.title}>Cancel Sessions</p>
            </div>
          </header>

          <form className={styles.form} onSubmit={handleSubmit}>
            <SelectInput2
              label="Reason"
              placeholder="Teacher Unavailable"
              required
              value={cancelReason}
              onChange={handleInputChange}
            />
            <SelectInput3
              dropdownOptions={dropDownOptions}
              field={"setAlert"}
              formData={sendAlert}
              handleInputChange={(field, value) =>
                setSendAlert(() => {
                  return {
                    [field]: value,
                  };
                })
              }
              label={"Send Instant Alert"}
              placeholder={"Send Instant Alert To Students"}
            />
            {/* {error && <div className={styles.errorText}>{error}</div>} */}
            <div className={styles.errorText}>{error}</div>

            <div className={styles.actions}>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={cancelling}
              >
                {cancelling ? "Cancelling" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CancelModal;
