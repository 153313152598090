import { useEffect, useState } from "react";
import styles from "./CreateModal.module.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  createSession,
  getBtaches,
  getChapters,
  getClasses,
  getSubjects,
  getTopics,
} from "../../../Store/API/API";
import { getSessionDetails } from "../../../Store/API/API";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import Spinner from "../../UserInterface/Spinner/Spinner";
import SelectInput from "../../UI/SelectInput/SelectInput";

const DateInput = ({ label, field, required, formData, handleInputChange }) => {
  return (
    <div className={styles.timeWrapper}>
      <label className={styles.label}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        <input
          type="date"
          className={styles.timeInput}
          value={formData[field] || ""}
          onChange={(e) => handleInputChange(field, e.target.value)}
        />
      </div>
    </div>
  );
};

const TimeInput = ({ label, field, required, formData, handleInputChange }) => {
  return (
    <div className={styles.timeWrapper}>
      <label className={styles.label}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        <input
          type="time"
          className={styles.timeInput}
          value={formData[field] || ""}
          onChange={(e) => handleInputChange(field, e.target.value)}
        />
      </div>
    </div>
  );
};

const SelectInputInternal = ({
  label,
  dropdownOptions,
  icon,
  required,
  placeholder,
  formData,
  handleInputChange,
  field,
  isMulti,
  width,
}) => {
  // console.log("field", field, formData[field]);

  // console.log("Select Input triggered: ", field);

  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        {icon && (
          <img loading="lazy" src={icon} alt="" className={styles.icon} />
        )}
        <Select
          defaultValue={[formData?.course]}
          isMulti={isMulti}
          name="colors"
          options={dropdownOptions}
          className="basic-multi-select"
          placeholder={placeholder}
          value={formData[field]}
          onChange={(selectedOption) =>
            handleInputChange(field, selectedOption)
          }
          styles={{
            control: (base) => ({
              ...base,
              border: "none", // Remove border
              boxShadow: "none", // Remove default focus outline
              width: width,
            }),
            container: (base) => ({
              ...base,
              width: width,
            }),
          }}
        />
      </div>
    </div>
  );
};

const AlertInput = ({
  label,
  dropdownOptions,
  icon,
  required,
  placeholder,
  formData,
  handleInputChange,
  field,
}) => {
  // console.log("field", field, formData[field]);
  // console.log("Select Input2 triggered: ", field);

  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        {icon && (
          <img loading="lazy" src={icon} alt="" className={styles.icon} />
        )}
        <SelectInput
          width={"100%"}
          options={dropdownOptions}
          value={formData}
          handleInputChange={handleInputChange}
          field={field}
          placeHolder={placeholder}
          border={"none"}
        />
      </div>
    </div>
  );
};

function CreateModal({
  onClose,
  setCreatingSession,
  handleUpdateSession,
  modalType,
  sessionId,
}) {
  const width = window.innerWidth;

  const handleClose = () => onClose(false);

  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    course: [{ value: batchState?.groupId, label: batchState?.batchName }],
    tutor: null,
    date: "",
    timeFrom: "",
    timeTo: "",
    class: null,
    subject: null,
    chapter: null,
    topic: "",
    mode: { value: "Online", label: "Online" },
    setAlert: {
      label: "No",
      value: 0,
    },
  });

  const handleSubmit = async (e, sessionId) => {
    e.preventDefault();

    if (
      formData.course === null ||
      formData.course.length === 0 ||
      !formData.tutor ||
      !formData.date ||
      !formData.timeFrom ||
      !formData.timeTo ||
      !formData.class ||
      !formData.subject ||
      !formData.chapter ||
      !formData.topic ||
      !formData.mode
    ) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Please fill all the fields",
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);

      return;
    }

    // handleClose();
    setCreatingSession(true);

    console.log("Form Data: ", formData);

    const tempData = {
      user_id: userState.userId,
      group_id: formData.course?.[0]?.value,
      tutor_id: formData.tutor?.value,
      fromDate: formData.date,
      fromTime: formData.timeFrom,
      toTime: formData.timeTo,
      session_class: formData.class?.value,
      session_subject: formData.subject?.value,
      session_chapter: formData.chapter?.value,
      session_topic: formData.topic?.map((item) => item.value),
      type: formData.mode?.value || "Online",
      session_id: sessionId,
      batches: formData.course?.map((item) => item.value),
      sendAlert: formData.setAlert.value,
    };

    console.log("Session Data: ", tempData);

    const data = await createSession(tempData, userState.token, dispatch);

    setCreatingSession(false);

    if (data.type === "Success") {
      setFormData({
        course: [{ value: batchState?.groupId, label: batchState?.batchName }],
        tutor: null,
        date: "",
        timeFrom: "",
        timeTo: "",
        class: null,
        subject: null,
        chapter: null,
        topic: "",
        mode: null,
      });

      handleUpdateSession("upcoming");
      handleClose();

      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Session created successfully",
          alertType: "success",
        })
      );

      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
    } else {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: data.message,
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 10000);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log("Form Data: ", formData);
  };

  const [dropDownOptions, setDropDownOptions] = useState({
    batch: [{ value: batchState?.groupId, label: batchState?.batchName }],
    tutor: [],
    class: [],
    subject: [],
    chapter: [],
    mode: [
      { value: "Online", label: "Online" },
      { value: "Offline", label: "Offline" },
    ],
    setAlert: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
  });

  const fetchBatches = async (callingFrom) => {
    const data = await getBtaches(
      userState.token,
      userState.userId,
      "",
      "",
      dispatch
    );

    // console.log("Batch Data: ", data);

    if (data) {
      if (callingFrom === "edit") {
        return data;
      }

      const selectedBatch = data?.filter(
        (item) => item?.group_id === batchState?.groupId
      );

      const tutors = selectedBatch?.[0]?.tutor;

      const tutorData = tutors?.map((item) => ({
        value: item?.user_id,
        label: item?.first_name,
      }));

      const setSelectedTutor = tutorData?.find(
        (item) => item?.value === userState.userId
      );

      // console.log("Selected Tutor: ", setSelectedTutor);

      setFormData((prev) => ({
        ...prev,
        tutor: setSelectedTutor,
      }));

      setDropDownOptions((prev) => ({
        ...prev,
        batch: data?.map((item) => ({
          value: item?.group_id,
          label: item?.group_name,
        })),
        tutor: tutorData,
      }));
    }
  };

  const fetchTopics = async ({ value }) => {
    const data = await getTopics(
      formData.class.value,
      formData.subject.value,
      value,
      dispatch
    );

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        topic: data,
      }));
    }
  };

  const fetchChapters = async ({ value }) => {
    const data = await getChapters(formData.class.value, value, dispatch);
    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        chapter: data,
      }));
    }
  };

  const fetchSubjects = async ({ value }) => {
    const data = await getSubjects(value, dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        subject: data,
      }));
    }
  };

  const fetchClasses = async () => {
    const data = await getClasses(dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        class: data,
      }));
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));

    if (field === "class") {
      setFormData((prev) => ({
        ...prev,
        subject: null,
        chapter: null,
        topic: "",
      }));
      fetchSubjects(value);
    }

    if (field === "subject") {
      setFormData((prev) => ({
        ...prev,
        chapter: null,
        topic: "",
      }));
      fetchChapters(value);
    }

    if (field === "chapter") {
      setFormData((prev) => ({
        ...prev,
        topic: "",
      }));
      fetchTopics(value);
    }
  };

  const processSessionData = async (data) => {
    console.log("data", data);

    const multipleBatchArray = [];
    // if (data.batchIds.length > 1) {
    // console.log("Multiple Batches");

    const batches = await fetchBatches("edit");
    // console.log("Batches: ", batches);

    for (let i = 0; i < data.batchIds?.length; i++) {
      const batch = batches?.find(
        (item) => item?.group_id === data.batchIds[i]
      );

      multipleBatchArray.push({
        value: batch?.group_id,
        label: batch?.group_name,
      });
    }

    // console.log("Multiple Batch Array: ", multipleBatchArray);
    // }

    const tempData = {
      course: multipleBatchArray,
      tutor: { value: data?.session_tutor_id, label: data?.session_tutor_name },
      date: data?.fromDate,
      timeFrom: data?.fromTime,
      timeTo: data?.toTime,
      class: { value: data?.session_class, label: data?.session_class },
      subject: { value: data?.session_subject, label: data?.session_subject },
      chapter: { value: data?.session_chapter, label: data?.session_chapter },
      topic: data?.session_topic?.map((item) => ({
        value: item,
        label: item,
      })),
      mode: { value: data?.type, label: data?.type },
    };

    setDropDownOptions((prev) => {
      return {
        ...prev,
        subject: data?.all_subjects,
        chapter: data?.all_chapters,
        topic: data?.all_topics,
      };
    });

    // console.log("Temp Data: ", tempData);

    setFormData(tempData);
  };

  const fetchSessionDetails = async () => {
    const data = await getSessionDetails(sessionId, dispatch);

    if (data?.statuscode === "200") {
      processSessionData(data.data);
    } else {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: data.message,
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
    }
  };

  useEffect(() => {
    // fetching batches
    fetchBatches();
    // fetching classes
    fetchClasses();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modalType === "edit") {
      console.log("Session Id: ", sessionId);

      fetchSessionDetails();
    }

    // eslint-disable-next-line
  }, [sessionId]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* <div className={styles.crossIcon}>
            <CloseIcon onClick={handleClose} />
          </div> */}
          <header className={styles.header}>
            <div className={styles.headerContainer}>
              <p className={styles.title}>
                {modalType === "edit" ? "Edit Session" : "Create Live Session"}
              </p>
            </div>
          </header>

          <form className={styles.form}>
            <SelectInputInternal
              label="Select Course"
              placeholder="Select Batch"
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d3a1c55656a37b94e40dec5775ede8ab72164373dfbd3252bd9c95d7a545f0fe?placeholderIfAbsent=true&apiKey=65c3b0a390fd4311b72fcd91fc18e875"
              dropdownOptions={dropDownOptions?.batch}
              field={"course"}
              isMulti={true}
              required
              width={"100%"}
              formData={formData}
              handleInputChange={handleInputChange}
            />

            <div className={styles.grid}>
              <DateInput
                label="Session Date"
                placeholder="Select date"
                icon="https://cdn.builder.io/api/v1/image/assets/TEMP/142bf89b75926edc803a4c8538be1d12a70b9dcc35fa5d08edd4c782ee37e85a?placeholderIfAbsent=true&apiKey=65c3b0a390fd4311b72fcd91fc18e875"
                required
                field={"date"}
                formData={formData}
                handleInputChange={handleInputChange}
              />

              <TimeInput
                label="Time (From)"
                field={"timeFrom"}
                required
                formData={formData}
                handleInputChange={handleInputChange}
              />
              <TimeInput
                label="Time (To)"
                field={"timeTo"}
                required
                formData={formData}
                handleInputChange={handleInputChange}
              />

              <SelectInputInternal
                label="Class"
                placeholder="Select Class"
                dropdownOptions={dropDownOptions?.class}
                required
                field={"class"}
                width={width <= 900 ? "100%" : "180px"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
              <SelectInputInternal
                label="Subject"
                placeholder="Select Subject"
                field={"subject"}
                dropdownOptions={dropDownOptions?.subject}
                required
                width={width <= 900 ? "100%" : "180px"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
              <SelectInputInternal
                label="Chapter"
                placeholder="Select chapter"
                field={"chapter"}
                dropdownOptions={dropDownOptions?.chapter}
                required
                width={width <= 900 ? "100%" : "180px"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
            </div>

            <div style={{marginBottom: "-10px"}} >
              <SelectInputInternal
                label="Select Topics"
                placeholder="Select Topics"
                icon="addtopic.svg"
                dropdownOptions={dropDownOptions?.topic}
                field={"topic"}
                isMulti={true}
                required
                width={width <= 900 ? "100%" : "650px"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
            </div>

            <div className={styles.grid}>
              <SelectInputInternal
                label="Mode"
                placeholder="Mode"
                field={"mode"}
                dropdownOptions={dropDownOptions?.mode}
                required
                width={width <= 900 ? "100%" : "200px"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
              <SelectInputInternal
                label="Select Tutor"
                placeholder="Select Tutor"
                field={"tutor"}
                dropdownOptions={dropDownOptions?.tutor}
                required
                width={width <= 900 ? "100%" : "180px"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
              <AlertInput
                dropdownOptions={dropDownOptions}
                field={"setAlert"}
                formData={formData}
                label={"Send Instant Alert"}
                placeholder={"Send Instant Alert To Students"}
              />
            </div>

            <div className={styles.actions}>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                onClick={(e) => {
                  // modalType === "edit" ? handleUpdate(e) : handleSubmit(e);
                  handleSubmit(e, sessionId);
                }}
              >
                {modalType === "edit" ? "Update" : "Create"} Session
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateModal;
