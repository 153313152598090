import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { IoEyeOffOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Context/AuthContext";
import {
  getBtaches,
  getSchoolCode,
  loginEmail,
} from "../../../../Store/API/API";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../../Store/Data/Slices/UserDataSlice";
import Cookies from "universal-cookie";
import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";

function Login2() {
  const cookies = new Cookies();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [schoolLogoUrl, setSchoolLogoUrl] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch();
 

  // change favicon based on hostname
  const hostname = window.location.hostname;
  // console.log(hostname, "hostname");
  const school = hostname.split(".")[0];
  // const school = 'dpsg';
  // console.log(school, "school");
  const changeFavicon = (iconUrl, title, schoolCode, imageURL) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = iconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);

    if (title) {
      document.title = title;
    }

    const tempData = {
      schoolName: title,
      schoolCode: schoolCode,
      favicon: iconUrl,
      schoolImage: imageURL,
    };

    localStorage.setItem("schoolData", JSON.stringify(tempData));
    dispatch(applicationActions.setSchoolData(tempData));
  };

  const fetchSchoolDetails = async () => {
    const data = await getSchoolCode(school, dispatch);
    // console.log(data, "schoolData");

    if (data?.statuscode === "200") {
      setSchoolLogoUrl(data?.data?.school_image);
      changeFavicon(
        data?.data?.fevicon,
        data?.data?.school_name,
        data?.data?.school_code,
        data?.data?.school_image
      );
    }
  };

  useEffect(() => {
    // if (school === 'dpsg') changeFavicon('dpsg.png', "Delhi Public School Ghaziabad");
    if (school === "dpsg") changeFavicon("dpsg.png", "GSAT-2025");
    else {
      fetchSchoolDetails();
    }

    // eslint-disable-next-line
  }, [school]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!username || !password) {
      setError("Please enter both username and password.");
      return;
    }
    const credentials = btoa(`${username}:${password}`);
    const data = await loginEmail(credentials, dispatch);
    // console.log(data, "userData");
    let tempData = {
      token: data?.token,
      userId: data?.user_id,
      instituteId: data?.institute_id,
      firstName: data?.first_name,
      lastName: data?.last_name,
      userType: data?.user_type,
      hideDashboard: data?.hide_dashboard,
      inst: data?.inst,
      remark: data?.remark,
      pdfScore: data?.getScore,
      leftBar: data?.left_config,
    };

    // console.log(tempData);
    if (data?.default_batch) {
      let SelectedBatchData = {}
      if (data?.default_batch?.group_id) {
        SelectedBatchData = {
          groupId: data?.default_batch?.group_id,
          batchName: data?.default_batch.batch_name,
          batchColor: data?.default_batch.batch_color,
          batchUserType: tempData?.userType === "teacher" ? "student" : tempData?.userType,
        };
      }
      
      
      // console.log(SelectedBatchData, "SelectedBatchData");

      dispatch(userDataActions.setUserData(tempData));
      dispatch(applicationActions.setBatchData(SelectedBatchData));
      // navigate('/dashboard');
      cookies.set("userData", JSON.stringify(tempData), {
        sameSite: "None",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      localStorage.setItem("userData", JSON.stringify(tempData));
      localStorage.setItem("batchData", JSON.stringify(SelectedBatchData));
    } else {
      setError("Invalid username or password.");
    }
  };

  const handleNavigate = () => {
    window.open("https://innovartan.com", "_blank");
  };

  return (
    <div className="flex flex-col lg:flex-row relative h-screen lg:min-h-screen xl:min-h-screen w-full font-metropolis">
      <div
        className="hidden lg:flex w-full lg:w-[60%] bg-cover bg-center flex-col justify-center items-center relative"
        style={{ backgroundImage: "url('/images/innovartan_login.png')" }}
      >
        <div className="flex flex-col text-left justify-center items-center text-white w-full lg:w-[80%] xl:w-[70%] pt-16 lg:pt-[35vh] lg:ml-16">
          <h2 className="text-xl md:text-2xl lg:text-[40px] xl:text-5xl font-bold mb-2 text-white">
            Unlock your endless learning
          </h2>
          {/* <p className="text-sm lg:text-lg xl:text-2xl font-normal">
            Begin your journey with us Today!!
          </p> */}
          <div className="flex flex-col mr-auto">
          <button
        onClick={handleNavigate}
        className="bg-primaryOrange rounded-lg px-4 py-2 text-lg text-center flex justify-center items-center"
      >
        Explore Now
      </button>
          </div>
        </div>
      </div>

      <div className="w-full px-4 sm:px-8 py-10 lg:py-20 lg:w-[40%] bg-white flex flex-col justify-center items-center">
        <div className="py-4 lg:py-10">
          {school === "dpsg" ? (
            <div className="text-center pb-4">
              <img
                src={`${school}.jpg`}
                alt={`${school}-logo`}
                className="mx-auto w-[70%] h-[50%] object-cover"
              />
            </div>
          ) : (
            <div className="text-center pb-4">
              {" "}
              {schoolLogoUrl && (
                <img
                  src={schoolLogoUrl}
                  alt="School Logo"
                  className="mx-auto w-24 h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40 object-cover"
                />
              )}
            </div>
          )}

          {/* <div className="text-center pb-4">            
  {schoolLogoUrl ? (
    <img
      src={schoolLogoUrl}
      alt="School Logo"
      className="mx-auto w-24 h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40 object-cover"
    />
  ) : (
    <img
      src="dpsg.jpg"
      alt="Default School Logo"
      className="mx-auto w-[70%] h-[50%] object-cover"
    />
  )}
</div>  */}
          <div className="text-center mb-6">
            <h2 className="text-lg lg:text-2xl xl:text-3xl font-bold mb-2">
              Login to your account
            </h2>
            <p className="hidden lg:block lg:text-base text-[#494949]">
              Begin your journey with us Today!!
            </p>
          </div>
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm lg:text-base font-medium text-black"
              >
                Email <span className="text-[#F36421]">*</span>
              </label>
              <div className="relative">
                <MdOutlineEmail className="absolute w-5 h-5 lg:w-6 lg:h-6 m-2 mx-3 text-[#494949]" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter Your Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 block w-full px-12 py-2 bg-backgroundBlue text-[#494949] rounded-lg focus:outline-none"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm lg:text-base font-medium text-black">
                Password <span className="text-[#F36421]">*</span>
              </label>
              <div className="relative">
                <RiLockPasswordLine className="absolute w-5 h-5 lg:w-6 lg:h-6 m-2 mx-3 text-[#494949]" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-12 py-2 bg-[#E7EDF6] text-[#494949] rounded-lg focus:outline-none"
                />
                {showPassword ? (
                  <IoEyeOutline
                    className="absolute w-5 h-5 lg:w-6 lg:h-6 text-[#494949] right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <IoEyeOffOutline
                    className="absolute w-5 h-5 lg:w-6 lg:h-6 text-[#494949] right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              type="submit"
              className="w-full bg-[#0743A3] text-white py-2 px-4 rounded-lg "
            >
              Login
            </button>
          </form>
          {/* Social Icons */}
          <div className="flex  pr-6 justify-center items-center mt-10 ">
            <ul className="flex gap-2">
              {[
                {
                  icon: FaFacebookF,
                  link: "https://www.facebook.com/innovartan/",
                },
                { icon: FaXTwitter, link: "https://x.com/innovartan" },
                {
                  icon: FaLinkedinIn,
                  link: "https://www.linkedin.com/company/innovartan",
                },
                {
                  icon: FaInstagram,
                  link: "https://www.instagram.com/innovartan",
                },
                {
                  icon: FaYoutube,
                  link: "https://www.youtube.com/@Innovartanlearning",
                },
              ].map(({ icon: Icon, link }, idx) => (
                <li
                  key={idx}
                  className="bg-[#073278] rounded-lg h-4 w-4 p-4 text-center flex justify-center items-center"
                >
                  <button
                    onClick={() =>
                      window.open(link, "_blank", "noopener,noreferrer")
                    }
                    className="text-white"
                  >
                    <Icon />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <footer className="text-textLightgrey text-center text-sm mt-8">
            Copyright © {new Date().getFullYear()} Innovartan | All Rights
            Reserved
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Login2;
