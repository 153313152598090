// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionsBottomTab_container__WFLuE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8fdf4;
  padding: 10px 20px;
  border: 1px solid #d5e8d4;
  border-radius: 5px;
  flex-wrap: wrap;
}

.QuestionsBottomTab_item__ktM2Y {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #555;
}

.QuestionsBottomTab_item__ktM2Y strong {
  font-weight: 700;
  color: #000;
}

.QuestionsBottomTab_separator__t3Mu4 {
  width: 1px;
  height: 20px;
  background-color: #d5e8d4;
  margin: 0 10px;
}

@media (max-width: 768px) {
  .QuestionsBottomTab_container__WFLuE {
    flex-direction: column;
    align-items: flex-start;
  }

  .QuestionsBottomTab_item__ktM2Y {
    margin-bottom: 10px;
  }

  .QuestionsBottomTab_separator__t3Mu4 {
    display: none;
  }
}

.QuestionsBottomTab_verified__KYZiK {
  background-color: #8bc34a;
  color: #fff;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/OuestionTypes/QuestionsBottomTag/QuestionsBottomTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: #f8fdf4;\n  padding: 10px 20px;\n  border: 1px solid #d5e8d4;\n  border-radius: 5px;\n  flex-wrap: wrap;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  font-size: 14px;\n  color: #555;\n}\n\n.item strong {\n  font-weight: 700;\n  color: #000;\n}\n\n.separator {\n  width: 1px;\n  height: 20px;\n  background-color: #d5e8d4;\n  margin: 0 10px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .item {\n    margin-bottom: 10px;\n  }\n\n  .separator {\n    display: none;\n  }\n}\n\n.verified {\n  background-color: #8bc34a;\n  color: #fff;\n  font-size: 12px;\n  padding: 2px 6px;\n  border-radius: 3px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QuestionsBottomTab_container__WFLuE`,
	"item": `QuestionsBottomTab_item__ktM2Y`,
	"separator": `QuestionsBottomTab_separator__t3Mu4`,
	"verified": `QuestionsBottomTab_verified__KYZiK`
};
export default ___CSS_LOADER_EXPORT___;
