import React, { useEffect, useState } from "react";
import Calender from "../../../UI/Calender/Calender";
import styles from "./StudentAttendance2.module.css";
import SelectInput from "../../../UI/SelectInput/SelectInput";
import {
  getAttendanceMonth,
  getStudentAttendance,
} from "../../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";

const width = window.innerWidth;

const SelectInputField = ({
  placeholder,
  field,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <SelectInput
      width={width < 600 ? "100%" : "200px"}
      placeHolder={placeholder}
      options={dropDownoptions}
      field={field}
      handleInputChange={handleInputChange}
      value={dropDownValues}
    />
  );
};

const AttendanceCard = ({
  cardHeading1,
  cardType,
  attendanceData,
  currentDate,
  currentMonth,
}) => {
  const InnerCard = ({ data, attendanceDataLength, index }) => {
    const cardStyle = {
      present: { tag: "P", backgroundColor: "#74AE42" },
      absent: { tag: "A", backgroundColor: "#F32121" },
      unmark: { tag: "U", backgroundColor: "#494949" },
      holiday: { tag: "H", backgroundColor: "#1CB2B7" },
    };

    const tagType =
      data?.attendance.slice(0, 1).toLowerCase() + data?.attendance.slice(1);

    return (
      <div
        className={styles.innerCard}
        style={{ border: index === attendanceDataLength - 1 && "none" }}
      >
        <div className={styles.leftContainer}>
          <p className={styles.innerCardHeading}>{data?.subject}</p>
          <p className={styles.innerCardSubHeading}>
            {/* {`Attendance Time : ${data?.marked_at?.split(" ")[1]}`} */}
            {`Attendance Time : ${data?.marked_at}`}
          </p>
        </div>
        <div
          className={styles.innerCardButton}
          style={{ backgroundColor: cardStyle[tagType]?.backgroundColor }}
        >
          {cardStyle[tagType]?.tag}
        </div>
      </div>
    );
  };

  const formatDate = (currentMonth, currentDate) => {
    // Extract the month and year from currentMonth
    const [month, year] = currentMonth.split(" ");

    // Create a Date object
    const date = new Date(year, parseInt(month, 10) - 1, currentDate);

    // Format the date into "Mon, 02 Dec 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    return formattedDate;
  };

  const formattedDate = formatDate(currentMonth, currentDate);

  const attendanceDataLength = attendanceData?.length;

  return (
    <div className={styles.cardContainer}>
      <div
        className={styles.cardType}
        style={{
          color: cardType === "online" ? "#F36421" : "#9821F3",
          backgroundColor: cardType === "online" ? "#FEF0E9" : "#EED9FF",
        }}
      >
        {cardHeading1}
      </div>
      <div className={styles.innerContainer}>
        <div className={styles.cardHeader}>
          <p className={styles.cardHeading}>{formattedDate}</p>
          {/* <div className={styles.headerButton}>Present</div> */}
        </div>
        <div className={styles.cardBody}>
          {attendanceData?.length === 0 ? (
            <p className={styles.noData}>No Data Available</p>
          ) : (
            attendanceData?.map((item, index) => (
              <InnerCard
                key={index}
                data={item}
                attendanceDataLength={attendanceDataLength}
                index={index}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const StudentAttendance2 = () => {
  const currentDate = new Date();
  const currentMonthLabel = currentDate.toLocaleString("default", {
    month: "short",
  });
  const currentYear = currentDate.getFullYear();
  const currentMonthValue = `${currentDate.getMonth() + 1} ${currentYear}`; // Months are zero-based, so add 1.
  const currentDay = currentDate.getDate();

  const [selectedMonth, setSelectedMonth] = useState({
    month: {
      label: `${currentMonthLabel} ${currentYear}`,
      value: currentMonthValue,
    },
  });
  // const [selectedMonth, setSelectedMonth] = useState({
  //   month: { label: "Dec 2024", value: "12 2024" },
  // });
  const [selectedDay, setSelectedDay] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);
  const [displayOnlineData, setDisplayOnlineData] = useState(null);
  const [displayOfflineData, setDisplayOfflineData] = useState(null);

  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const [dropDownOptions, setDropDownOptions] = useState({
    month: [
      { label: "November 2024", value: "November 2024" },
      { label: "December 2024", value: "December 2024" },
    ],
  });

  const setCurrentDataAttendance = (attendanceData, dateData) => {
    const selectedDate = `${dateData.year}-${dateData.month
      .toString()
      .padStart(2, "0")}-${dateData.date.toString().padStart(2, "0")}`;
    console.log("Setting Data", selectedDate);

    // console.log(attendanceData);

    const onlineData = attendanceData?.online?.filter((item) => {
      const date = item.date;
      return date === selectedDate;
    });
    const offlineData = attendanceData?.offline?.filter((item) => {
      const date = item.date;
      return date === selectedDate;
    });

    setDisplayOnlineData(onlineData);
    setDisplayOfflineData(offlineData);

    // console.log("Filtered onlineData ", onlineData);
    // console.log("Filtered offlineData ", offlineData);
  };

  const fetchAttendance = async (monthData = selectedMonth, callUpdate) => {
    // console.log("fetchingNewData");

    // console.log(monthData);

    const year = monthData.month.value.split(" ")[1];
    const month = monthData.month.value.split(" ")[0];

    const attendanceData = {
      user_id: userState.userId,
      group_id: batchState.groupId,
      date: `${year}-${month}-01`,
    };

    const data = await getStudentAttendance(
      attendanceData,
      userState.token,
      dispatch
    );

    // console.log(data);

    const dateData = {
      date: currentDay,
      month,
      year,
    };

    if (data?.statuscode === "200") {
      setAttendanceData(data.data);
      if (!callUpdate) {
        setCurrentDataAttendance(data.data, dateData);
      }
    }
  };

  const fetchMonths = async () => {
    const data = await getAttendanceMonth(
      batchState.groupId,
      userState.token,
      dispatch
    );

    const monthtonumber = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };

    const monthYear = data?.map((item) => {
      const [month, year] = item.value.split(",");
      const monthNumber = monthtonumber[month.trim()];
      return {
        label: `${month.trim()} ${year.trim()}`,
        value: `${monthNumber} ${year.trim()}`,
      };
    });

    setDropDownOptions({ month: monthYear });

    fetchAttendance();

    // console.log(data, monthYear);
  };

  const handleInputChange = async (field, value) => {
    setSelectedMonth((prev) => ({
      ...prev,
      [field]: value, // Update the correct field (e.g., 'month')
    }));

    const updatedMonth = {
      ...selectedMonth,
      [field]: value,
    };

    setDisplayOfflineData(null);
    setDisplayOnlineData(null);

    fetchAttendance(updatedMonth, "true");
  };

  const handleDateChange = async (date) => {
    setSelectedDay(date);

    if (date === null) {
      return;
    }

    if (attendanceData === null) {
      return;
    }

    const dateData = {
      date: date,
      month: selectedMonth.month.value.split(" ")[0],
      year: selectedMonth.month.value.split(" ")[1],
    };

    setCurrentDataAttendance(attendanceData, dateData);

    // console.log("Date Show change", date, selectedMonth);
    // console.log("attendaceData", attendanceData);

    // console.log(data.data);
  };

  useEffect(() => {
    fetchMonths();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <p className={styles.heading}>My Attendance</p>
        <SelectInputField
          dropDownValues={selectedMonth}
          dropDownoptions={dropDownOptions}
          handleInputChange={handleInputChange}
          field={"month"}
        />
      </div>
      <div className={styles.calenderWrapper}>
        <Calender
          value={selectedMonth}
          selectedDay={selectedDay}
          setSelectedDay={handleDateChange}
        />
      </div>
      <div className={styles.cardWrapper}>
        <AttendanceCard
          cardHeading1={"Offline Attendance"}
          cardType={"offline"}
          attendanceData={displayOfflineData}
          currentMonth={selectedMonth.month.value}
          currentDate={selectedDay}
        />
        <AttendanceCard
          cardHeading1={"Online Attendance"}
          cardType={"online"}
          attendanceData={displayOnlineData}
          currentMonth={selectedMonth.month.value}
          currentDate={selectedDay}
        />
      </div>
    </div>
  );
};

export default StudentAttendance2;
