import React, { useState, useEffect, useRef } from "react";
import styles from "./Calender.module.css";

const HorizontalCalendar = ({ value, selectedDay, setSelectedDay }) => {
  // State for the selected month and year
  const [monthYear, setMonthYear] = useState({
    month: 12, // December (Months are 1-based for this state)
    year: 2024,
  });

  const [days, setDays] = useState([]);
  // const [selectedDay, setSelectedDay] = useState(null);

  // Ref for the container to control scrolling
  const containerRef = useRef(null);

  // Function to generate the days of the month
  const generateDays = (month, year) => {
    const daysInMonth = new Date(year, month, 0).getDate(); // Get days in the month

    const generatedDays = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month - 1, day);
      const dayName = currentDate.toLocaleDateString("en-US", {
        weekday: "short",
      }); // Get weekday name
      generatedDays.push({ day, dayName });
    }

    return generatedDays;
  };

  useEffect(() => {
    // console.log(value);
    if (value && value.month && value.month.value) {
      const [month, year] = value.month.value.split(" ");
      setMonthYear({
        month: parseInt(month, 10), // Ensure the month is a number
        year: parseInt(year, 10), // Ensure the year is a number
      });
    }
  }, [value]);

  // Update days when monthYear changes
  useEffect(() => {
    const newDays = generateDays(monthYear.month, monthYear.year);
    setDays(newDays);

    // Automatically select today's date if it matches the month and year
    const today = new Date();
    if (
      today.getMonth() + 1 === monthYear.month &&
      today.getFullYear() === monthYear.year
    ) {
      setSelectedDay(today.getDate());
    } else {
      setSelectedDay(null); // Clear selection if not in the current month/year
    }
  }, [monthYear]);

  // Scroll the selected day into the center of the screen
  useEffect(() => {
    if (selectedDay !== null && containerRef.current) {
      const selectedElement = containerRef.current.querySelector(
        `[data-day="${selectedDay}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [selectedDay]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div style={{ display: "flex", gap: "10px" }}>
        {days.map(({ day, dayName }) => (
          <div
            key={day}
            data-day={day}
            onClick={() => setSelectedDay(day)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "60px",
              padding: "10px",
              backgroundColor: selectedDay === day ? "#FEF0E9" : "",
              color: selectedDay === day ? "#F36421" : "#000000",
              textAlign: "center",
              borderRadius: "25px",
              cursor: "pointer",
              transition: "0.3s",
            }}
          >
            <div style={{ fontWeight: "700", fontSize: "20px" }}>{day}</div>
            <div
              style={{
                fontWeight: "400",
                fontSize: "12px",
                color: selectedDay === day ? "#f36421" : "#94A3B8",
              }}
            >
              {dayName}
            </div>
            <div
              style={{
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                marginTop: "5px",
                backgroundColor: selectedDay === day ? "#F36421" : "",
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalCalendar;
