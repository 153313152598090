import React from "react";
import styles from "./QuestionsBottomTab.module.css";

const QuestionsBottomTag = () => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <span>Answer :</span>
        <strong>A</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Chapter :</span>
        <strong>Alternating Current Reaction</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Difficulty Level :</span>
        <strong>1</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Is Verified :</span>
        <span className={styles.verified}>Verified</span>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Last Updated :</span>
        <strong>26 Nov 2024, 04:02 PM</strong>
      </div>
    </div>
  );
};

export default QuestionsBottomTag;
