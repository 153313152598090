import React from "react";
import styles from "./CustomModal.module.css";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // Don't render anything if modal is closed

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <CloseIcon style={{fontSize: "30px"}}/>
        </button>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
