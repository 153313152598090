import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./AddTopicModal.module.css";
import { use } from "react";
import { getSessionTopics, updateSessionTopics } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";

const AddTopicModal = ({ onClose, sessionId, handleUpdateSession }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dropDownOptions, setDropDownOption] = useState();
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);

  const handleSubmit = async () => {
    const selectedValueArray = selectedValue.map((item) => item.value);

    console.log("selectedValueArray", selectedValueArray);

    const tempData = {
      user_id: userState.userId,
      session_id: sessionId,
      session_topic: selectedValueArray,
    };

    const data = await updateSessionTopics(tempData, userState.token , dispatch);
    console.log("data", data);
    

    if (data.statuscode === "200") {
      handleUpdateSession(
        "topicChanged",
        sessionId,
        "",
        "",
        selectedValueArray
      );
      onClose();
    }
  };

  const fetchTopics = async () => {
    const data = await getSessionTopics(sessionId, dispatch);

    if (data.statuscode === "200") {
      setSelectedValue(data?.data?.session_topic);
      setDropDownOption(data?.data?.all_topics);
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.crossIcon}
        onClick={() => {
          onClose();
        }}
      ></div>
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <p className={styles.title}>Edit Topics</p>
        </div>
      </header>
      <div className={styles.form}>
        <Select
          defaultValue={selectedValue}
          isMulti
          name="colors"
          options={dropDownOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          value={selectedValue}
          onChange={(e) => setSelectedValue(e)}
        />
        {error && <div className={styles.errorText}>{error}</div>}

        <div className={styles.actions} onClick={handleSubmit}>
          <button className={styles.submitButton}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default AddTopicModal;
