import React, { useState, useEffect } from "react";
import "./Session.css";
import { FaDownload, FaPlay } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroupSession, getRecordingTime } from "../../../Store/API/API";
import VideoPlayer from "../Dashboard/Videoplayer/VideoPlayer";
import Spinner from "../../UserInterface/Spinner/Spinner";
import CardView from "./CardView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

const PastSessions = () => {
  const [selectedMP4, setSelectedMP4] = useState(null);
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const userState = useSelector((state) => state.userData.userData);
  const [pastSessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null); // Move this to be row-specific
  const [openMenuId, setOpenMenuId] = useState(null); // Store the id of the row whose menu is open

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(index); // Set the row's id to open the specific menu
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuId(null); // Close the menu
  };

  const fetchSessions = async () => {
    try {
      const data = await getGroupSession(
        userState,
        batchState.groupId,
        "past",
        dispatch
      );
      if (data) {
        console.log("First API data:", data);
        setSessions(data);
      }
    } catch (error) {
      // console.error("Failed to fetch sessions:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const formatVideoData = (apiData) => {
    return {
      video_id: apiData.sessionId || "",
      url: apiData.recordingUrlmp4 || "",
      title: apiData.session_chapter || "Untitled",
      course_id: apiData.course_id || "",
      session_id: apiData.sessionId || "",
      duration: "0",
      thumb_nail: "",
    };
  };
  const openVideoModal = async (videoUrl, data) => {
    // console.log("Video URL:", videoUrl);
    // console.log("Received data:", data);
    const formattedData = formatVideoData(data);
    // console.log("Formatted data:", formattedData);
    setSelectedVideoUrl(videoUrl);
    setSelectedMP4(formattedData);
    try {
      // console.log("data", data);
      const tempData = {
        user_id: userState.userId,
        content_type: "session",
        content_id: data.sessionId,
      };
      const apiData = await getRecordingTime(
        tempData,
        userState.token,
        dispatch
      );
      // console.log("Get recording data:", apiData);
      if (apiData) {
        // console.log("current view time ", apiData.current_view_time);
        setCurrentViewTime(apiData.current_view_time);
        // console.log("set  view time ", currentViewTime);
      }
    } catch (error) {
      // console.error("Failed to fetch recording time:", error);
    } finally {
      setShowVideoModal(true);
    }
  };
  
  const closeVideoModal = () => {
    setSelectedVideoUrl("");
    setSelectedMP4();
    setCurrentViewTime(0);
    setShowVideoModal(false);
  };

  useEffect(() => {
    fetchSessions();

    // eslint-disable-next-line
  }, [userState]);

  if (isLoading) {
    return <Spinner />;
  }
  const renderPast = (item) => (
    <div>
      <div>
        <strong>Start Date:</strong> <span>{item.date}</span>
      </div>
      <div>
        <strong>Start Time:</strong> <span>{item.fromTime}</span>
      </div>
      <div>
        <strong>Subject:</strong> <span>{item.session_subject}</span>
      </div>
      <div>
        <strong>Chapter:</strong> <span>{item.session_chapter}</span>
      </div>
      <div>
        <strong>Topics:</strong>
        <span>{item.topics}</span>
      </div>
      <div className="flex flex-row ">
        <strong>Action:</strong>
        <span className="icons">
          {item.notes !== 0 && (
            <div className="mx-auto text-sm text-slate-600 pr-6 pl-2">
              <FaDownload
                className="text-blue-800 cursor-pointer"
                onClick={() => window.open(item.notes)}
              />
            </div>
          )}
          {item.recordingUrl !== 0 && (
            <div className="mx-auto text-sm text-slate-600 justify-between">
              <FaPlay
                className="text-blue-800 cursor-pointer"
                onClick={() => openVideoModal(item.recordingUrl, item)}
              />
            </div>
          )}
        </span>
      </div>
      {showVideoModal && (
        <div className="videoModal">
          <div className="videoModalContent">
            <span className="close" onClick={closeVideoModal}>
              &times;
            </span>
            <VideoPlayer
              videoUrl={selectedVideoUrl}
              fileData={selectedMP4}
              currentViewTimeParent={currentViewTime}
              type="session"
            />
          </div>
        </div>
      )}
    </div>
  );
  return (
    <div className="flex h-[95vh] w-[100%]">
      <div className="overflow-auto flex md:w-[100%] w-full flex-col px-6 py-6 h-full bg-gray-100 mt-10">
        <div className="form-row">
          <div className="form-buttons">
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white hrcolor Buttons"
              to="/upcomming-session"
            >
              Upcoming
            </Link>
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white activecolor Buttons"
              to="/past-session"
            >
              Past
            </Link>
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white hrcolor Buttons"
              to="/cancel-session"
            >
              Canceled
            </Link>
          </div>
        </div>
        <div className=" bg-white rounded-lg py-1 md:p-3 w-full border-t-2 border-t-orange-500 main-container">
          <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <Thead>
              <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
                <Th className="py-2 px-0 text-center">Start Date</Th>
                <Th className="py-2 px-0 text-center">Start Time</Th>
                <Th className="py-2 px-0 text-center">Subject</Th>
                <Th className="py-2 px-0 text-center">Chapter</Th>
                <Th className="py-2 px-0 text-center">Topics</Th>
                <Th className="py-2 px-0 text-center">Actions</Th>
              </Tr>
            </Thead>
            <Tbody className="text-gray-600 text-sm font-light">
              {pastSessions?.length === 0 && (
                <Tr>
                  <Td
                    colSpan="7"
                    className="py-2 px-6 text-center text-orange-500 font-bold"
                  >
                    No Past Sessions Found
                  </Td>
                </Tr>
              )}

              {pastSessions?.map((item, index) => (
                <Tr
                  className="border-b border-gray-200 hover:bg-gray-100"
                  key={index}
                >
                  <Td className="py-2 px-0 text-center">{item.date}</Td>
                  <Td className="py-2 px-0 text-center">{item.fromTime}</Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_subject}
                  </Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_chapter}
                  </Td>
                  <Td className="py-2 px-0 text-center">
                    {item.topics || "-"}
                  </Td>

                  <Td className="py-2 px-0 text-center">
                    {item.notes !== 0 && item.recordingUrl !== "" ? (
                      <div>
                        <Button
                          id="basic-button"
                          aria-controls={
                            openMenuId === index ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            openMenuId === index ? "true" : undefined
                          }
                          onClick={(event) => handleClick(event, index)}
                        >
                          <MenuIcon />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openMenuId === index}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{ width: "200px" }}
                        >
                          {item.notes !== 0 && (
                            <MenuItem onClick={() => window.open(item.notes)}>
                              Download Notes
                            </MenuItem>
                          )}
                          {item.recordingUrl !== "" && (
                            <MenuItem
                              onClick={() =>
                                openVideoModal(item.recordingUrl, item)
                              }
                            >
                              Play Recording
                            </MenuItem>
                          )}
                        </Menu>{" "}
                      </div>
                    ) : (
                      "-"
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {showVideoModal && (
            <div className="videoModal">
              <div className="videoModalContent">
                <span className="close" onClick={closeVideoModal}>
                  &times;
                </span>
                <VideoPlayer
                  videoUrl={selectedVideoUrl}
                  fileData={selectedMP4}
                  currentViewTimeParent={currentViewTime}
                  type="session"
                />
              </div>
            </div>
          )}
        </div>
        <CardView
          data={pastSessions}
          renderContent={renderPast}
          noDataMessage=" No Past Sessions"
        />
      </div>
    </div>
  );
};
export default PastSessions;
