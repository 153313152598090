import React from "react";
import styles from "./FIB.module.css";
import Tag from "../Tag/Tag";
// import Integer from "../Integer/Integer";
import EditIcon from "@mui/icons-material/Edit";
import QuestionsBottomTag from "../QuestionsBottomTag/QuestionsBottomTag";
import QuestionTopTag from "../QuestionsTopTag/QuestionTopTag";

const FIB = ({
  correctOption,
  fibCount,
  question,
  questionOrder,
  location,
  questionId,
  questionMarks,
}) => {
  // Generate placeholders for options based on fibCount
  const fib_Count = fibCount * 1;

  const options = Array.from({ length: fib_Count }, (_, index) => index);

  return (
    <div className={styles.mcqContainer}>
      <QuestionTopTag
        questionId={questionId}
        questionMarks={questionMarks}
        location={location}
      />
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            {location !== "QB" ? (
              <input
                type="text"
                className={styles.questionInputBox}
                value={questionOrder}
              />
            ) : (
              <div className={styles.questionInputBox}>{questionOrder}</div>
            )}
            <div
              style={{ display: "ruby" }}
              dangerouslySetInnerHTML={{
                __html: question || " ",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        {options?.map((item, index) => (
          <div
            key={index}
            className={styles.mcqOptionContainer}
            style={{ marginLeft: "30px" }}
          >
            <div
              className={
                correctOption[index] === "True"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `Answer: ${correctOption[index] || ""}`,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      {location === "QB" && (
        <div className={styles.QuestionBankContainer}>
          <QuestionsBottomTag />
        </div>
      )}
    </div>
  );
};

export default FIB;
