import React from "react";
import styles from "./QuestionTopTag.module.css";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

const QuestionTopTag = ({ questionId, questionMarks, location }) => {
  console.log(questionId, questionMarks, location);

  return (
    <div className={styles.container}>
      <div className={styles.LeftContainer}>
        <div className={styles.leftText}>ID: {questionId}</div>{" "}
        {/* Use curly braces */}
        <div className={styles.separator}></div>
        <div className={styles.leftText}>Marks: {questionMarks}</div>
      </div>
      <div className={styles.rightContainer}>
        <Tooltip title="Edit Marks" arrow>
          <div className={styles.editMarksContainer}>
            <p className={styles.leftText}>Marks :</p>
            <input type="text" className={styles.marksInputField} value={"4"} />
          </div>
        </Tooltip>
        {location !== "QB" && (
          <Tooltip title="Report" arrow>
            <div className={styles.reportButton}>
              <ReportGmailerrorredIcon />
            </div>
          </Tooltip>
        )}
        {location === "QB" && (
          <Tooltip title="Edit" arrow>
            <div className={styles.editButton}>
              <EditIcon />
            </div>
          </Tooltip>
        )}
        {location !== "QB" && (
          <Tooltip title="Delete" arrow>
            <div className={styles.deleteButton}>
              <DeleteOutlineIcon />
            </div>
          </Tooltip>
        )}
        {/* {location === "QB" && <div className={styles.saveButton}>Save</div>} */}
      </div>
    </div>
  );
};

export default QuestionTopTag;
