import React, { useState, useRef, useEffect } from "react";
import styles from "./Filter.module.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const Filter = ({ options, parentFunction, clearFunction, filterPosition }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const animatedComponents = makeAnimated();

  // Custom styles for the Select dropdown
  const selectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F1EFFE", // Custom background color
      borderColor: "#ccc",
      borderRadius: "8px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#D9D1FE" : provided.backgroundColor,
      color: state.isSelected ? "#000" : provided.color,
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Close dropdown when clicking outside
  //   useEffect(() => {
  //     const handleOutsideClick = (event) => {
  //       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //         setIsOpen(false);
  //       }
  //     };
  //     document.addEventListener("mousedown", handleOutsideClick);
  //     return () => document.removeEventListener("mousedown", handleOutsideClick);
  //   }, []);

  const topPosition =
    filterPosition === "left"
      ? "50px"
      : filterPosition === "center"
      ? "50px"
      : filterPosition === "right"
      ? "50px"
      : filterPosition.top;
  const leftPosition =
    filterPosition === "left"
      ? "-200px"
      : filterPosition === "center"
      ? "-100px"
      : filterPosition === "right"
      ? "0px"
      : filterPosition.left;

  return (
    <div className={styles.container}>
      {/* Filter Icon */}
      <div className={styles.filterIcon} onClick={toggleDropdown}>
        <FilterAltIcon />
      </div>

      {/* Filter Dropdown */}
      {isOpen && (
        <div
          className={styles.filterContainer}
          ref={dropdownRef}
          style={{ top: topPosition, left: leftPosition }}
        >
          {options?.map((item, index) => {
            return (
              <div>
                {item.type === "select" ? (
                  <Select
                    key={index}
                    options={item?.options} // Dynamic options passed as props
                    placeholder={item?.placeholder || "Select option"} // Dynamic placeholder passed as props
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={item?.selectedValue}
                    // isSearchable={true}
                    onChange={item?.onChange} // Callback function for selected option
                    styles={{
                      ...selectStyle, // Spread any existing styles
                      container: (provided) => ({
                        ...provided,
                        width: 220, // Set fixed width
                      }),
                    }}
                  />
                ) : item.type === "search" ? (
                  <input
                    key={index}
                    type="text"
                    placeholder={item?.placeholder || "Search"}
                    value={item?.selectedValue}
                    onChange={(e) => item?.onChange(e.target.value)}
                    className={styles.searchInput}
                  />
                ) : item.type === "date" ? (
                  <input
                    key={index}
                    type="date"
                    placeholder={item?.placeholder || "Select Date"}
                    value={item?.selectedValue}
                    onChange={(e) => item?.onChange(e.target.value)}
                    className={styles.dateInput}
                  />
                ) : null}
              </div>
            );
          })}
          <div className={styles.buttonContainer}>
            <div className={styles.applyButton} onClick={parentFunction}>
              Apply
            </div>
            <div className={styles.clearButton}>Clear</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
