import React from "react";
import styles from "./ViewAnalysis.module.css";
import { useLocation } from "react-router-dom";

const ViewAnalysis = () => {
  const location = useLocation();
  const { framUrl } = location.state || {};

  return (
    <div className={styles.container}>
      <iframe
        src={framUrl || ""}
        title="View Analysis"
        className={styles.frameBox}
      ></iframe>
    </div>
  );
};

export default ViewAnalysis;
