import React, { useState } from "react";
import styles from "./Pagination.module.css";

const Pagination = ({ totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);

  const pagesPerTab = 5;
  const totalTabs = Math.ceil(totalPages / pagesPerTab);

  // Calculate the page numbers for the current tab
  const startPage = (currentTab - 1) * pagesPerTab + 1;
  const endPage = Math.min(startPage + pagesPerTab - 1, totalPages);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handleTabChange = (direction) => {
    if (direction === "next" && currentTab < totalTabs) {
      setCurrentTab(currentTab + 1);
    } else if (direction === "prev" && currentTab > 1) {
      setCurrentTab(currentTab - 1);
    }
  };

  return (
    <div className={styles.paginationContainer}>
      {/* Previous Tab Button */}
      <button
        className={styles.tabButton}
        onClick={() => handleTabChange("prev")}
        disabled={currentTab === 1}
      >
        &lt; Prev
      </button>

      {/* Page Buttons */}
      {pageNumbers.map((number) => (
        <button
          key={number}
          className={`${styles.pageButton} ${
            currentPage === number ? styles.activePage : ""
          }`}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </button>
      ))}

      {/* Next Tab Button */}
      <button
        className={styles.tabButton}
        onClick={() => handleTabChange("next")}
        disabled={currentTab === totalTabs}
      >
        Next &gt;
      </button>
    </div>
  );
};

export default Pagination;
