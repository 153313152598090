import React, { useEffect, useRef, useState } from "react";
import styles from "./TeacherAttendance.module.css";
import Select from "react-select";
import SelectInput from "../../../UI/SelectInput/SelectInput";
import {
  getAttendanceMonth,
  getDayWiseSession,
  getTutorAttendance,
  markBatchAttendance,
  markStudentAttendances,
} from "../../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import makeAnimated from "react-select/animated";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {heading} <span className={styles.required}>*</span>
      </label>
      <div className={styles.dropDownWrapper}>
        {type === "normal" ? (
          <SelectInput
            width={"100%"}
            placeHolder={placeholder}
            options={dropDownoptions}
            field={field}
            handleInputChange={handleInputChange}
            value={dropDownValues}
            backgroundColor={"#1b3156"}
            border={"none"}
            dropdownTextColor={"#FFFFFF"}
          />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              defaultValue={dayjs(dropDownValues?.date)}
              minDate={dayjs("2024-04-01")}
              maxDate={dayjs()}
              format="DD/MM/YYYY"
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  color: "white", // Text color
                  backgroundColor: "#1b3156", // Background color
                  borderRadius: "4px",
                  padding: "5px 10px",
                  height: "40px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1b3156", // Outline color
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "white", // Outline color on hover
                  },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "white", // Outline color when focused
                  },
                "& .MuiInputBase-input": {
                  color: "white", // Text color inside input
                },
                "& .MuiButtonBase-root": {
                  color: "white", // Calendar icon color
                },
                "& .MuiPickersDay-dayWithMargin": {
                  color: "white", // Text color in calendar
                },
                "& .MuiPickersDay-dayWithMargin.Mui-selected": {
                  backgroundColor: "#1b3156", // Selected day background
                  color: "white", // Selected day text color
                },
              }}
              onChange={(e) => handleInputChange("date", e.$d)}
            />
          </LocalizationProvider>
        )}
      </div>
    </div>
  );
};

const TeacherAttendance = () => {
  const batchState = useSelector((state) => state.applicationData.batchData);
  const userState = useSelector((state) => state.userData.userData);
  const [progressFlag, setProgressFlag] = React.useState("");
  const dispatch = useDispatch();

  const [attendanceData, setAttendanceData] = React.useState([]);

  const [dropDownValues, setDropDownValues] = React.useState({
    date: dayjs().format("YYYY-MM-DD"),
    session: "",
  });

  const [dropDownoptions, setDropDownOptions] = React.useState({
    markAs: [
      {
        value: "Unmark",
        label: "Unmark",
      },
      {
        value: "Present",
        label: "Present",
      },
      {
        value: "Absent",
        label: "Absent",
      },
      {
        value: "Holiday",
        label: "Holiday",
      },
    ],
  });

  const handleInputChange = async (field, selectedOption) => {
    console.log("selectedOption", selectedOption, field);

    if (field === "date") {
      const formattedDate = dayjs(selectedOption).format("YYYY-MM-DD");
      setAttendanceData([]);
      setDropDownValues((prevState) => ({
        ...prevState,
        session: "",
        [field]: formattedDate,
      }));
      await fetchSessions(formattedDate);
    } else if (field === "session") {
      setDropDownValues((prevState) => ({
        ...prevState,
        [field]: selectedOption,
      }));

      fetchTutorAttendance(selectedOption.value);
    } else {
      setDropDownValues((prevState) => ({
        ...prevState,
        [field]: selectedOption,
      }));
    }
  };

  const markStudentAttendance = async (studentId, buttonId) => {
    console.log("studentId", studentId, buttonId);

    const attendanceFlag =
      buttonId === 0
        ? "Unmark"
        : buttonId === 1
        ? "Present"
        : buttonId === 2
        ? "Absent"
        : "Holiday";

    const attendanceDataTemp = {
      user_id: userState.userId,
      group_id: batchState.groupId,
      date: dropDownValues.date,
      session_id: dropDownValues.session.value,
      attendance: attendanceFlag,
      student_id: studentId,
    };

    const data = await markStudentAttendances(
      attendanceDataTemp,
      userState.token,
      dispatch
    );

    console.log("data", data);

    if (data?.statuscode === "200") {
      const tempData = attendanceData.map((item) => {
        if (item.user_id === studentId) {
          item.attendance = attendanceFlag;
        }
        return item;
      });
      setAttendanceData(tempData);
    }
  };

  const StudentCard = ({ studentData }) => {
    const [openMenu, setOpenMenu] = useState();
    const menuRef = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setOpenMenu(false); // Close the menu
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const MenuOptions = ({ name, color, logo, studentData, buttonId }) => {
      return (
        <div
          className={styles.menuOptions}
          onClick={() => {
            markStudentAttendance(studentData?.user_id, buttonId);
            setOpenMenu(false);
          }}
        >
          <img src={logo} alt="Absent" className={styles.logo} />
          <p className={styles.menuName} style={{ color: color }}>
            {name}
          </p>
        </div>
      );
    };

    const cardColor =
      studentData?.attendance === "Present"
        ? "#74AE42"
        : studentData?.attendance === "Absent"
        ? "#F32121"
        : studentData?.attendance === "Holiday"
        ? "#0743A3"
        : "#494949";

    return (
      <div className={styles.studentCard}>
        <div className={styles.studentDetails}>
          <p className={styles.studentName}>
            {studentData?.name}
            {/* {studentData?.user_id} */}
          </p>
          <p
            className={styles.mobileAttendanceCard}
            style={{ backgroundColor: cardColor }}
          >
            {studentData?.attendance}
          </p>
        </div>
        <div className={styles.rightPannel}>
          <div className={styles.studentAttendance}>
            <p
              className={styles.attendanceCard}
              style={{ backgroundColor: cardColor }}
            >
              {studentData?.attendance}
            </p>
          </div>
          <div
            className={styles.filterIcon}
            style={{
              cursor:
                dropDownValues?.date !== dayjs().format("YYYY-MM-DD")
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={() =>
              dropDownValues?.date === dayjs().format("YYYY-MM-DD") &&
              setOpenMenu(true)
            }
          >
            <FilterAltIcon />
          </div>
          {openMenu && (
            <div className={styles.filterContainer} ref={menuRef}>
              <MenuOptions
                color={"#494949"}
                logo={"unmark.png"}
                name={"Unmark"}
                studentData={studentData}
                buttonId={0}
              />
              <MenuOptions
                color={"#74AE42"}
                logo={"present.png"}
                name={"Present"}
                studentData={studentData}
                buttonId={1}
              />
              <MenuOptions
                color={"#F32121"}
                logo={"absent.png"}
                name={"Absent"}
                studentData={studentData}
                buttonId={2}
              />
              <MenuOptions
                color={"#0743A3"}
                logo={"holiday.png"}
                name={"Holiday"}
                studentData={studentData}
                buttonId={3}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleMarkAttendance = async () => {
    setProgressFlag("");

    if (
      !dropDownValues.session ||
      !dropDownValues.date ||
      !dropDownValues.markAs
    ) {
      setProgressFlag("Please select all fields");
      setTimeout(() => {
        setProgressFlag("");
      }, 3000);
      return;
    }

    const attendanceDataTemp = {
      user_id: userState.userId,
      group_id: batchState.groupId,
      date: dropDownValues.date,
      session_id: dropDownValues.session.value,
      attendance: dropDownValues.markAs.value,
    };

    const data = await markBatchAttendance(
      attendanceDataTemp,
      userState.token,
      dispatch
    );

    console.log("data", data);

    if (data?.statuscode === "200") {
      const studentAttendance = attendanceData.map((item) => {
        return {
          user_id: item.user_id,
          name: item.name,
          attendance: dropDownValues.markAs.value,
        };
      });
      setAttendanceData(studentAttendance);

      setProgressFlag("Attendance marked successfully");
      setTimeout(() => {
        setProgressFlag("");
      }, 3000);
    }
  };

  const fetchSessions = async (date) => {
    const attendanceData = {
      user_id: userState.userId,
      group_id: batchState.groupId,
      date: date,
    };

    const data = await getDayWiseSession(
      attendanceData,
      userState.token,
      dispatch
    );
    // console.log("data", data);

    if (data.statuscode === "200") {
      setDropDownOptions((prevState) => ({
        ...prevState,
        session: data.data,
      }));
    }
  };

  const fetchTutorAttendance = async (sessionId) => {
    const tempData = {
      user_id: userState.userId,
      group_id: batchState.groupId,
      date: dropDownValues.date,
      session_id: sessionId,
    };
    const data = await getTutorAttendance(tempData, userState.token, dispatch);

    if (data?.statuscode === "200") {
      setAttendanceData(data?.data?.students);
    }
  };

  useEffect(() => {
    fetchSessions(dayjs().format("YYYY-MM-DD"));

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <p className={styles.heading}>My Attendance</p>
      </div>
      <div className={styles.dropDownContainer}>
        <SelectInputField
          field={"date"}
          heading={"Date"}
          placeholder={"Select Date"}
          type={"date"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />
        <SelectInputField
          field={"session"}
          heading={"Session"}
          placeholder={"Select Session"}
          type={"normal"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />
        <SelectInputField
          field={"markAs"}
          heading={"Mark as (Complete Class)"}
          placeholder={"Select status"}
          type={"normal"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />

        <div style={{ alignSelf: "flex-end" }}>
          <p
            style={{
              color:
                progressFlag === "Please select all fields" ? "red" : "green",
            }}
          >
            {progressFlag}
          </p>
          <div
            className={styles.markButton}
            style={{
              backgroundColor:
                dropDownValues?.date !== dayjs().format("YYYY-MM-DD")
                  ? "#fac1a6"
                  : "",
              cursor:
                dropDownValues?.date !== dayjs().format("YYYY-MM-DD")
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={() => {
              dropDownValues?.date === dayjs().format("YYYY-MM-DD") &&
                handleMarkAttendance();
            }}
          >
            <p>Mark Attendance</p>
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.studentCountContainer}>
          <div className={styles.memberCount}>
            <span className={styles.count}>{attendanceData?.length}</span>
            <p>Number of members</p>
          </div>
        </div>
        <div className={styles.studentCards}>
          {attendanceData?.map((item, index) => {
            return <StudentCard key={index} studentData={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default TeacherAttendance;
