import React, { useRef } from "react";

function PDFViewer({ selectedPDF }) {
  const iframeRef = useRef(null);

  const handleFullScreen = () => {
    if (iframeRef.current) {
      const element = iframeRef.current;
      try {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(); // Safari
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen(); // Firefox
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen(); // IE/Edge
        } else {
          console.error("Fullscreen API is not supported in this browser.");
        }
      } catch (error) {
        console.error("Error requesting fullscreen:", error);
      }
    }
  };

  return (
    <div className="w-full h-[70vh]">
      <iframe
        ref={iframeRef}
        src={`https://app.innovartan.com/flipBook/flip.php?link=${selectedPDF}`}
        title="PDF Viewer"
        className="w-full h-full cursor-pointer"
        allowFullScreen
        onClick={handleFullScreen}
      />
    </div>
  );
}

export default PDFViewer;
