import React from "react";
import Select from "react-select";

const SelectInput = ({
  options,
  value,
  handleInputChange,
  placeHolder,
  width,
  border,
  backgroundColor,
  field,
  height,
  textColor, // Added textColor prop
  dropdownTextColor, // Added dropdown text color prop
}) => {
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      defaultValue={field ? value[field] : value}
      value={field ? value[field] : value}
      name="color"
      options={field ? options[field] : options}
      onChange={(selectedOption) => handleInputChange(field, selectedOption)}
      placeholder={placeHolder}
      styles={{
        control: (base) => ({
          ...base,
          border: border ? border : base.border,
          boxShadow: border ? "none" : base.boxShadow,
          backgroundColor: backgroundColor
            ? backgroundColor
            : base.backgroundColor,
          width: width,
          height: height,
          color: textColor ? textColor : "white", 
        }),
        container: (base) => ({
          ...base,
          width: width,
          height: height,
        }),
        singleValue: (base) => ({
            ...base,
            color: dropdownTextColor || "black", // Customize selected value text color
          }),
      }}
    />
  );
};

export default SelectInput;
