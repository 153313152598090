import React, { useEffect, useState } from "react";
import styles from "./ViewQuestionBank.module.css";
import { getAttendanceMonth } from "../../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../../UI/SelectInput/SelectInput";
import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
import FIB from "../../../UI/OuestionTypes/FIB/FIB";
import TF from "../../../UI/OuestionTypes/TF/TF";
import MCQMA from "../../../UI/OuestionTypes/MCQMA/MCQMA";
import Integer from "../../../UI/OuestionTypes/Integer/Integer";

const width = window.innerWidth;

const SelectInputField = ({
  placeholder,
  field,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <SelectInput
      width={width < 600 ? "100%" : "200px"}
      placeHolder={placeholder}
      options={dropDownoptions}
      field={field}
      handleInputChange={handleInputChange}
      value={dropDownValues}
    />
  );
};

const TypeCard = ({ number, heading, selected }) => {
  return (
    <div
      className={styles.typeCardContainer}
      style={{ backgroundColor: selected ? "#ffc107" : "" }}
    >
      <div className={styles.numberCard}>{number}</div>
      <p className={styles.typeCardHeading}>{heading}</p>
    </div>
  );
};

const ViewQuestionBank = () => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const [selectedMonth, setSelectedMonth] = useState({
    month: { label: "Dec 2024", value: "12 2024" },
  });
  const [dropDownOptions, setDropDownOptions] = useState({
    month: [
      { label: "November 2024", value: "November 2024" },
      { label: "December 2024", value: "December 2024" },
    ],
  });
  const handleInputChange = async (field, value) => {
    setSelectedMonth((prev) => ({
      ...prev,
      [field]: value, // Update the correct field (e.g., 'month')
    }));
  };

  const fetchMonths = async () => {
    const data = await getAttendanceMonth(
      batchState.groupId,
      userState.token,
      dispatch
    );

    const monthtonumber = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };

    const monthYear = data?.map((item) => {
      const [month, year] = item.value.split(",");
      const monthNumber = monthtonumber[month.trim()];
      return {
        label: `${month.trim()} ${year.trim()}`,
        value: `${monthNumber} ${year.trim()}`,
      };
    });

    setDropDownOptions({ month: monthYear });
    // console.log(data, monthYear);
  };

  const mcqQuestion = {
    question: "What is the capital of India?",
    options: [
      {
        name: "Answer Is A AND B",
        answer_value: "1",
        answer_key: "A",
      },
      {
        name: "Answer Is B AND B",
        answer_value: "2",
        answer_key: "B",
      },
      {
        name: "Answer Is C AND B",
        answer_value: "3",
        answer_key: "C",
      },
      {
        name: "Answer Is  D AND B",
        answer_value: "4",
        answer_key: "D",
      },
    ],
    questionOrder: 1,
    answer: "A",
  };
  const fibQuestion = {
    question: "What is the capital of India?",
    options: ["red", "blue"],
    questionOrder: 1,
    answer: "A",
  };
  const trueFalseQuestion = {
    question: "What is the capital of India?",
    questionOrder: 1,
    answer: "A",
  };
  const mcqmaQuestion = {
    question: "What is the capital of India?",
    options: [
      {
        name: "Answer Is A AND B",
        answer_value: "1",
        answer_key: "A",
      },
      {
        name: "Answer Is B AND B",
        answer_value: "2",
        answer_key: "B",
      },
      {
        name: "Answer Is C AND B",
        answer_value: "3",
        answer_key: "C",
      },
      {
        name: "Answer Is  D AND B",
        answer_value: "4",
        answer_key: "D",
      },
    ],
    questionOrder: 1,
    answer: "A,C,D",
  };
  const integerQuestion = {
    question: "What is the capital of India?",
    questionOrder: 1,
    answer: "#2#,2",
  };

  //   useEffect(() => {
  //     fetchMonths();
  //     // eslint-disable-next-line
  //   }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <p className={styles.heading}>Assignment</p>
        <SelectInputField
          dropDownValues={selectedMonth}
          dropDownoptions={dropDownOptions}
          handleInputChange={handleInputChange}
          field={"month"}
        />
      </div>
      <div className={styles.questionTypeContainer}>
        <TypeCard
          number={"10"}
          heading={"Fill in the Blanks"}
          selected={true}
        />
        <TypeCard number={"10"} heading={"True/False"} />
        <TypeCard number={"10"} heading={"MC - Single Ans"} />
        <TypeCard number={"10"} heading={"MC - Multiple Ans"} />
        <TypeCard number={"10"} heading={"Integer Type"} />
      </div>
      <div className={styles.questionsContainer}>
        <MCQ
          answer={mcqQuestion.answer}
          correctOption={mcqQuestion.options}
          question={mcqQuestion.question}
          questionOrder={mcqQuestion.questionOrder}
          // location={"QB"}
        />
        <FIB
          correctOption={fibQuestion.options}
          question={fibQuestion.question}
          questionOrder={1}
          fibCount={2}
          // location={"QB"}
        />
        <TF
          answer={trueFalseQuestion.answer}
          question={trueFalseQuestion.question}
          questionOrder={trueFalseQuestion.questionOrder}
          // location={"QB"}
        />
        <MCQMA
          answer={mcqmaQuestion.answer}
          question={mcqmaQuestion.question}
          questionOrder={mcqmaQuestion.questionOrder}
          correctOption={mcqmaQuestion.options}
          // location={"QB"}
        />
        <Integer
          correctOption={integerQuestion.answer}
          question={integerQuestion.question}
          questionOrder={integerQuestion.questionOrder}
          // location={"QB"}
        />
      </div>
    </div>
  );
};

export default ViewQuestionBank;
