import React, { useState, useEffect } from "react";
import "./Course.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetails } from "../../../Store/API/API";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";
import BillingSummary from "./BillingSummary";
const CourseDetail = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [courseDetails, setCourse] = useState([]);
  const [selected, setSelected] = useState("One Time");
  const [cartview, setcartview] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { course_id } = location.state;

  const fetchCourse = async () => {
    const data = await getCourseDetails(course_id, userState.userId, dispatch);
    if (data) {
      // console.log(data);

      setCourse(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCourse();
  }, [userState]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="course-detail-container">
      <div className="mainContainer">
        <div className="course-content  ">
          <div className="course-header">
            <div className="course-image-placeholder">
              <img
                src={courseDetails.cover_photo_url}
                className="cardImage"
                alt="course"
              ></img>
            </div>
            <div className="course-info">
              <h1>
                <strong>{courseDetails.name}</strong>
              </h1>
              <ul>
                <li className="subDetail">
                  {courseDetails?.subject}
                  {" | "} {courseDetails?.class} {" | "}
                  {courseDetails?.curriculum}
                </li>
                <li>{courseDetails?.sub_title}</li>
              </ul>
            </div>
          </div>
          <div className="course-meta">
            <div className="flex ">
              <div className="course-meta-icon">
                <img src="/language.png" alt="language" />
              </div>
              <span>
                Language: <strong>English</strong>
              </span>
            </div>
            <div className="flex">
              <div className="course-meta-icon">
                <img src="/clock.png" alt="clock" />
              </div>
              <span>
                Total Validity:{" "}
                <strong>{courseDetails.validity_days || 0} days </strong>
              </span>
            </div>
            <div className="flex">
              <div className="course-meta-icon">
                <img src="/stairs.png" alt="stairs" />
              </div>
              <span>
                Level: <strong>Beginner</strong>
              </span>
            </div>
          </div>
          <div className="course-actions">
            <span className="course-price">
              ₹{" "}
              {Number(courseDetails?.content_amount) -
                Number(courseDetails?.gst_amount)}
            </span>
          </div>
          <div className="course-learn">
            <h2>
              <strong>What you'll learn</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: courseDetails.topic }}
            ></div>
          </div>
          <div className="course-learn">
            <h2>
              <strong>Requirements</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: courseDetails.requirement }}
            ></div>
          </div>
          <div className="course-learn">
            <h2>
              <strong>Description</strong>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: courseDetails.description }}
            ></div>
          </div>
        </div>
        <div className="course-sidebar">
          {!cartview ? (
            <>
              {courseDetails.can_buy === 1 ? (
                <button
                  className="buy-now"
                  onClick={() => {
                    setcartview(true);
                  }}
                >
                  Buy Now
                </button>
              ) : (
                <button
                  className="buy-now"
                  onClick={() => {
                    navigate("/course-content", {
                      state: { course_name: courseDetails.course_name },
                    });
                  }}
                >
                  View Details
                </button>
              )}
              <button
                className="buy-now"
                style={{ marginTop: "10px", marginBottom: "-10px" }}
              >
                Pay Outstanding EMI
              </button>
              <div className="course-learn">
                <h2>
                  <strong>This course includes </strong>
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: courseDetails.include }}
                ></div>
              </div>
            </>
          ) : (
            <div className="">
              <button
                className="backButton"
                onClick={() => {
                  setcartview(false);
                }}
              >
                {"<-"}
              </button>
              <div className="heading mb-2">Select a Payment Method</div>
              <div className="payment-method">
                <div className=" flex gap-8 mb-4">
                  <span
                    className={`${
                      selected === "One Time"
                        ? "bg-[#C7CCFF] text-[#000e8f]"
                        : ""
                    }`}
                    onClick={() => {
                      setSelected("One Time");
                    }}
                  >
                    One Time
                  </span>
                  <span
                    className={`${
                      selected === "EMI" ? "bg-[#C7CCFF] text-[#000e8f]" : ""
                    }`}
                    onClick={() => setSelected("EMI")}
                  >
                    EMI
                  </span>
                </div>
              </div>

              <BillingSummary />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CourseDetail;
